import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { InfoAbout } from '../../types/Content';
import { SectionProps } from '../../types/Section';
import InfoItemsHorizontal from '../../components/InfoItemsHorizontal';
import InfoItemsVertical from '../../components/InfoItemsVertical';
import { KnownBreakpoints, SUB_LAPTOP } from '../../../../common/constants';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        marginBottom: '40px',
    },
    left: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '50px',
        flex: '1 1',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            paddingRight: 0,
            flex: '1 1 100%',
        },
    }),
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginTop: '-10px',
    },
    image: {
        borderRadius: '50%',
        maxWidth: '100%',
        height: 'auto',
    },
};

const InfoAbout: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: {
        title,
        text,
        image,
        items,
        hasApplyButton,
    } } = props as { data: InfoAbout };
    const imageAlt = image.data?.attributes.alternativeText || 'About Us';

    return <Box sx={ styles.section }>
        <SectionTitle title={ title } />
        <SectionDivider location='top' />

        <Box sx={ styles.content }>
            <Box sx={ styles.left }>
                <Markdown text={ text } />
            </Box>

            { !isMobile && <Box sx={ styles.right }>
                <Image
                    priority
                    alt={ imageAlt }
                    src={ image.data?.attributes.url || '' }
                    width={ image.data?.attributes.width || 251 }
                    height={ image.data?.attributes.height || 251 }
                    style={ styles.image }
                />
            </Box> }
        </Box>

         { !isMobile
            ? <InfoItemsHorizontal
                data={ items }
                hasConnections={ false }
                iconColor={ theme.palette.common.white }
            />
            : <InfoItemsVertical
                data={ items }
                hasConnections={ false }
                iconSize={ 40 }
                iconColor={ theme.palette.common.white }
                iconBackgroundColor={ theme.palette.primary.main }
                textColor={ theme.palette.text.primary }
            />
        }


        { hasApplyButton && <React.Fragment>
            <SectionDivider location='bottom' />
            <SectionApplyButton direction={ isMobile ? 'column' : 'row'} />
        </React.Fragment> }
    </Box>;
};

export default InfoAbout;
