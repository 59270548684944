import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { Footer1 } from '../../types/Footer';
import { SectionProps } from '../../types/Section';
import IconButton from '@mui/material/IconButton';
import CmsExternalIcon from '../../components/CmsExternalIcon';
import { EXTRA_SMALL, KnownBreakpoints } from '../../../../common/constants';
import Markdown from '../../components/Markdown';
import Trustwave from './Trustwave';
import SmartLink from '../../../SmartLink';
import useReplaceCmsVars from '../../../../common/hooks/useReplaceCmsVars';

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: '4px',
    },
    left: (theme: Theme) => ({
        display: 'flex',
        columnGap: '22px',
        rowGap: '4px',
        flexGrow: 1,
        flexWrap: 'wrap',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            justifyContent: 'center',
        },
    }),
    menu: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px',
        flexWrap: 'wrap',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
        },
    }),
    linkItem: {
        display: 'flex',
        columnGap: '8px',
    },
    menuItem: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        color: withoutThemeSwitcher
            ? theme.palette.common.white
            : theme.palette.text.primary
        ,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
    }),
    menuDivider: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        color: withoutThemeSwitcher
            ? theme.palette.common.white
            : theme.palette.text.primary
        ,
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            display: 'none',
        },
    }),
    externalLinks: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    externalLink: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${ withoutThemeSwitcher
            ? theme.palette.common.white
            : theme.palette.text.primary
        }`,
        width: '22px',
        height: '22px',
        borderRadius: '50%',
    }),
    externalLinkIcon: {
        fontSize: '12px',
    },
    copyright: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        color: withoutThemeSwitcher
            ? theme.palette.common.white
            : theme.palette.text.primary
        ,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
    }),
    divider: {
        margin: '22px 0',
    },
    bottom: {
        display: 'flex',
        gap: '60px',
    },
    text: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        color: withoutThemeSwitcher
            ? theme.palette.common.white
            : theme.palette.text.primary
        ,
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
    }),
    mobileBottom: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '20px',
        },
    }),
};

const Footer1: FC<SectionProps> = props => {
    const router = useRouter();
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const replaceCmsVars = useReplaceCmsVars();
    const { data } = props as { data: Footer1 };
    const copyright = replaceCmsVars(data.copyright);
    const {
        withoutThemeSwitcher,
    } = props as { withoutThemeSwitcher: boolean };

    const handleClick = (link: string) => {
        if (!link) {
            return;
        }
        router.push(replaceCmsVars(link));
    };

    return <Box sx={ styles.content }>
        <Box sx={ styles.top }>
            <Box sx={ styles.left }>
                <Box sx={ styles.menu }>
                    { data.menu.map((item, idx) => (
                    <Box key={ item.id } sx={ styles.linkItem }>
                        { (idx !== 0) &&
                            <Box sx={ styles.menuDivider(
                                theme,
                                withoutThemeSwitcher,
                            ) }>|</Box>
                        }
                        <SmartLink href={ '/' + item.link }>
                            <Typography component="h4" sx={ styles.menuItem(
                                theme,
                                withoutThemeSwitcher,
                            ) }>
                                { item.title }
                            </Typography>
                        </SmartLink>
                    </Box>)) }
                </Box>
                { !isMobile && <Box sx={ styles.externalLinks }>
                    { data.externalLinks.map(item => (
                        <IconButton
                            key={ item.id }
                            sx={ styles.externalLink(
                                theme,
                                withoutThemeSwitcher,
                            ) }
                            onClick={ () => handleClick(item.link) }
                        >
                            <CmsExternalIcon
                                name={ item.icon }
                                sx={ styles.externalLinkIcon }
                                fill={ props.withoutThemeSwitcher
                                    ? '#FFF'
                                    : undefined
                                }
                            />
                        </IconButton>
                    )) }
                </Box> }
            </Box>

            { !isMobile && <Typography component="h4" sx={ styles.copyright(
                theme,
                withoutThemeSwitcher,
            ) }>
                { copyright }
            </Typography> }
        </Box>

        <Divider sx={ styles.divider } />

        <Box sx={ styles.bottom }>
            <Markdown
                text={ data.text }
                sx={{ ...styles.text(theme, withoutThemeSwitcher) }}
            />
            { !isMobile && <Trustwave /> }
        </Box>

        { isMobile && <Fragment>
            <Divider sx={ styles.divider } />
            <Box sx={ styles.mobileBottom }>
                <Typography component="h4" sx={ styles.copyright(
                    theme,
                    withoutThemeSwitcher,
                ) }>
                    { copyright }
                </Typography>
                <Trustwave />
            </Box>
        </Fragment> }
    </Box>;
};

export default Footer1;
