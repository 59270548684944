import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { InfoWithTestimonials } from '../../types/Content';
import { SectionProps } from '../../types/Section';
import InfoItemsHorizontal from '../../components/InfoItemsHorizontal';
import InfoItemsVertical from '../../components/InfoItemsVertical';
import ItemTestimonial from '../../components/ItemTestimonial';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    text: (theme: Theme) => ({
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
        marginBottom: '50px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '24px',
        },
    }),
    testimonials: (theme: Theme) => ({
        display: 'flex',
        gap: '58px 69px',
        flexWrap: 'wrap',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            gap: '37px',
        },
    }),
};

const InfoWithTestimonials: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: {
        infoWithItems,
        testimonials,
        hasApplyButton,
    } } = props as { data: InfoWithTestimonials };

    return <Box sx={ styles.section } >
        <SectionTitle title={ infoWithItems.title } />
        { isMobile && <SectionDivider location='top' /> }

         { !isMobile
            ? ( <React.Fragment>
                <Markdown
                    text={ infoWithItems.text }
                    sx={{ ...styles.text(theme) }}
                />
                <InfoItemsHorizontal
                    data={ infoWithItems.items }
                    hasConnections={ infoWithItems.hasConnections }
                    iconColor={ theme.palette.common.white }
                />
            </React.Fragment> )
            : <InfoItemsVertical
                data={ infoWithItems.items }
                hasConnections={ false }
                iconSize={ 40 }
                iconColor={ theme.palette.common.white }
                iconBackgroundColor={ theme.palette.primary.main }
                textColor={ theme.palette.text.primary }
            />
        }

        <SectionDivider location='middle' />
        <SectionTitle title={ testimonials.title } />

        <Box sx={ styles.testimonials }>
            { testimonials.items.map(item => (
                <ItemTestimonial key={ item.id } item={ item } />
            )) }
        </Box>

        { hasApplyButton && !isMobile && <React.Fragment>
            <SectionDivider location='bottom' />
            <SectionApplyButton />
        </React.Fragment> }

    </Box>;
};

export default InfoWithTestimonials;
