import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ContactPhoneIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 19 19' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M5.85988 1.76522C6.26745 2.17256 6.67648 2.57876 7.08223 2.98788C7.79772 3.7096 7.79917 4.55129 7.08643 5.26746C6.57519 5.7812 6.06688 6.29787 5.54578 6.80126C5.40799 6.93402 5.39715 7.0435 5.46702 7.21087C5.80924 8.03268 6.30398 8.76036 6.86065 9.4447C7.97949 10.82 9.24506 12.0408 10.7542 12.988C11.0773 13.1908 11.4349 13.3386 11.7716 13.5212C11.9448 13.6151 12.0635 13.5852 12.2021 13.442C12.7119 12.915 13.2341 12.3997 13.7551 11.8834C14.4374 11.2075 15.2939 11.2061 15.9794 11.8849C16.8168 12.7143 17.6505 13.5477 18.4809 14.3842C19.1774 15.0857 19.1718 15.9431 18.4713 16.6496C17.9981 17.1271 17.498 17.5804 17.0505 18.0807C16.3993 18.8089 15.5822 19.0447 14.6475 18.9932C13.2891 18.9185 12.0359 18.4679 10.829 17.8814C8.14641 16.5778 5.85321 14.7712 3.93459 12.4877C2.51603 10.7997 1.34382 8.96234 0.572856 6.8871C0.200066 5.88339 -0.0664508 4.85558 0.0145752 3.76824C0.0647103 3.09732 0.31669 2.52666 0.811255 2.05593C1.34221 1.55027 1.84811 1.01856 2.37116 0.504368C3.05609 -0.169163 3.91277 -0.167673 4.59931 0.506147C5.0232 0.922229 5.43984 1.3451 5.85967 1.76487L5.85988 1.76522Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default ContactPhoneIcon;
