import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ApplicationIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 40 40' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M9.4585 33.4163C8.69183 33.4163 8.021 33.1288 7.446 32.5538C6.871 31.9788 6.5835 31.308 6.5835 30.5413V22.8747H9.4585V30.5413H36.2918V6.58301H9.4585V14.2497H6.5835V3.70801C6.5835 2.94134 6.871 2.27051 7.446 1.69551C8.021 1.12051 8.69183 0.833008 9.4585 0.833008H36.2918C37.0585 0.833008 37.7293 1.12051 38.3043 1.69551C38.8793 2.27051 39.1668 2.94134 39.1668 3.70801V30.5413C39.1668 31.308 38.8793 31.9788 38.3043 32.5538C37.7293 33.1288 37.0585 33.4163 36.2918 33.4163H9.4585ZM3.7085 39.1663C2.94183 39.1663 2.271 38.8788 1.696 38.3038C1.121 37.7288 0.833496 37.058 0.833496 36.2913V6.58301H3.7085V36.2913H33.4168V39.1663H3.7085ZM20.9585 26.5163L18.946 24.5038L23.4022 19.9997H6.5835V17.1247H23.4022L18.946 12.6205L20.9585 10.608L28.9127 18.5622L20.9585 26.5163Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default ApplicationIcon;
