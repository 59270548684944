import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { RecentPostCard } from '../../types/Sidebar';
import HomePaperPad from '../../../PaperPad';
import BlockTitle from '../../components/BlockTitle';
import truncate from '../../../../common/helpers/truncate';
import SeeMoreButton from '../../components/SeeMoreButton';
import { KnownBreakpoints } from '../../../../common/constants';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        width: '100%',
        maxWidth: '325px',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            maxWidth: 'calc(50% - 10px)',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            maxWidth: 'unset',
        },
    }),
    paper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        alignItems: 'center',
        padding: '40px 30px 28px',
    },
    text: {
        fontSize: '14px',
    },
    readButton: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
};

const RecentPostCard: React.FunctionComponent<SectionProps> = props => {
    const { data } = props as { data: RecentPostCard };

    return <Box sx={ styles.container }>
        <HomePaperPad sx={ styles.paper }>
            <BlockTitle title={ data.title } />
            <Typography component="span" sx={ styles.text }>
                { truncate(data.text, 76) }
            </Typography>
            <Box sx={ styles.readButton }>
                <SeeMoreButton href={ data.slug }>
                    Read article
                </SeeMoreButton>
            </Box>
        </HomePaperPad>
    </Box>;
};

export default RecentPostCard;
