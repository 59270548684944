import React, { FunctionComponent } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { EXTRA_SMALL, KnownBreakpoints } from '../../../common/constants';
import { ItemText } from '../types/Item';
import Box from '@mui/material/Box';
import Markdown from './Markdown';
import { ItemsDirection } from '../types/Block';
import InfoItemIcon, { InfoItemIconType } from './InfoItemIcon';

const styles = {
    container: (direction: ItemsDirection) => ({
        display: 'flex',
        flexDirection: direction,
        flexWrap: direction === 'row' ? 'wrap' : 'nowrap',
        gap: '24px',
    }),
    item: (theme: Theme, direction?: ItemsDirection) => ({
        display: 'flex',
        alignItems: 'baseline',
        flexGrow: 1,
        gap: '10px',
        maxWidth: direction === 'row' ? '31%' : '100%',
        [theme.breakpoints.down(1400)]: {
            maxWidth: direction === 'row' ? '48%' : '100%',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    }),
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    title: (theme: Theme) => ({
        ...theme.typography.h2,
        lineHeight: '48.05px',
        marginBottom: '35px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '24px',
            lineHeight: '28.13px',
        },
    }),
};

type InfoItemsNumberedProps = {
    items: ItemText[];
    hasIcons?: boolean;
    icon?: InfoItemIconType;
    direction?: ItemsDirection;
};

const InfoItemsNumbered: FunctionComponent<InfoItemsNumberedProps> = ({
    items,
    hasIcons = true,
    icon = 'number',
    direction = 'row',
}) => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.container(direction) }>
        { items.map((item, idx) => (
            <Box key={ item.id } sx={ styles.item(theme, direction) }>
                { hasIcons && <Box sx={ styles.iconContainer }>
                    <InfoItemIcon icon={ icon }>
                        { idx + 1 }
                    </InfoItemIcon>
                </Box> }
                <Markdown text={ item.text } />
            </Box>
        )) }
    </Box>;
};

export default InfoItemsNumbered;
