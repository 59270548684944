import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
    KnownBreakpoints,
    SMALL,
    EXTRA_SMALL,
} from '../../../../common/constants';
import { SectionProps } from '../../types/Section';
import { Sidebar1 } from '../../types/Sidebar';
import HomePaperPad from '../../../PaperPad';
import HeadphoneKidIcon from '../../../../assets/SvgIcons/HeadphoneKidIcon';
import { officeHoursFormat } from '../../../../common/helpers/format';
import IsOfficeOpened from '../../../SupportInfo/IsOfficeOpened';
import CmsExternalIcon from '../../components/CmsExternalIcon';
import PaymentIcon from '../../../../assets/SvgIcons/PaymentIcon';
import SectionApplyButton from '../../components/SectionApplyButton';
import SmartLink from '../../../SmartLink';
import useReplaceCmsVars from '../../../../common/hooks/useReplaceCmsVars';

const styles = {
    sidebar: (theme: Theme) => ({
        display: 'flex',
        minWidth: '325px',
        width: '100%',
        maxHeight: '500px',
        position: 'sticky',
        top: 100,
        zIndex: 100,
        [theme.breakpoints.up(KnownBreakpoints.tablet)]: {
            flexDirection: 'column',
            maxHeight: 'unset',
        },
        [theme.breakpoints.down(SMALL)]: {
            maxHeight: 'unset',
            minWidth: 'unset',
        },
    }),
    paper: (theme: Theme, dt?: boolean) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        alignItems: 'center',
        padding: '44px 34px 34px',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            flexDirection: !dt ? 'row-reverse' : 'column',
            alignItems: !dt ? 'flex-start' : 'center',
            gap: !dt ? '5px' : '22px',
            padding: !dt ? '34px' : '44px 34px 34px',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: '22px',
            padding: '44px 34px 34px',
        },
    }),
    info: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        alignItems: 'center',
    },
    apply: (theme: Theme, dt?: boolean) => ({
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '35px',
        alignItems: 'center',
        [theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        )]: {
            height: !dt ? '100%' : 'inherit',
            gap: !dt ? '35px' : '22px',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            height: '100%',
            gap: '22px',
        },
    }),
    titleContainer: (theme: Theme, dt?: boolean) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        )]: {
            flexDirection: !dt ? 'row' : 'column',
            gap: '5px',
        },
    }),
    title: (theme: Theme) => ({
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28.13px',
            textAlign: 'center',
        },
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            textAlign: 'center',
        },
    }),
    phoneContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    phone: (theme: Theme) => ({
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18.75px',
        color: theme.palette.text.primary,
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28.13px',
        },
    }),
    officeHours: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    officeHoursText: (theme: Theme) => ({
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '14px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '14.06px',
        },
    }),
    externalLinks: {
        display: 'flex',
        gap: '14px',
    },
    externalLink: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${ theme.palette.text.primary }`,
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    }),
    externalLinkIcon: {
        fontSize: '22px',
    },
    divider: (theme: Theme, dt?: boolean) => ({
        width: '100%',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            width: !dt ? 'unset' : '100%',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '100%',
        },
    }),
    bottomTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
};

const Sidebar1: FC<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const isLaptop = useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        ),
    );
    const replaceCmsVars = useReplaceCmsVars();

    const { data } = props as { data: Sidebar1 };
    const { officeHours, externalLinks, disableTransformation: dt } = data;
    const phoneNumber = replaceCmsVars(data.phone);

    return <Box sx={ styles.sidebar(theme) as any }>
        <HomePaperPad sx={ styles.paper(theme, dt) }>
            <Box sx={ styles.info }>
                <Box sx={ styles.titleContainer(theme, dt) }>
                    <Typography component="h5" sx={ styles.title }>
                        { data.title }
                    </Typography>
                    { data.subtitle &&
                        <Typography component="h5" sx={ styles.title }>
                            { data.subtitle }
                        </Typography>
                    }
                </Box>
                <Box sx={ styles.phoneContainer }>
                    <HeadphoneKidIcon />
                    <Box sx={ styles.phone } >
                        <SmartLink href={ `tel:${ phoneNumber }` }>
                            { phoneNumber }
                        </SmartLink>
                    </Box>
                </Box>
                <Box sx={ styles.officeHours }>
                    <Typography component="span" sx={ styles.officeHoursText }>
                        { // TODO: rewrite OfficeHours and IsOfficeOpened components
                            officeHoursFormat(
                                officeHours.start,
                                officeHours.end,
                            )
                        }
                    </Typography>
                    <IsOfficeOpened />
                </Box>
                <Box sx={ styles.externalLinks }>
                    { externalLinks.map(item => (
                        <IconButton
                            key={ item.id }
                            sx={ styles.externalLink }
                            href={ replaceCmsVars(item.link) }
                        >
                            <CmsExternalIcon
                                name={ item.icon }
                                sx={ styles.externalLinkIcon }
                            />
                        </IconButton>
                    )) }
                </Box>
            </Box>

            { isMobile
                ? <Divider
                        orientation={ 'horizontal' }
                        sx={ styles.divider(theme, dt) }
                    />
                : <Divider
                        orientation={
                            dt
                                ? 'horizontal'
                                : isLaptop ? 'vertical' : 'horizontal'
                        }
                        sx={ styles.divider(theme, dt) }
                    />
            }

            <Box sx={ styles.apply(theme, dt) }>
                <Box sx={ styles.bottomTitleContainer }>
                    <Typography component="h5" sx={ styles.title }>
                        Get your money today
                    </Typography>
                    <PaymentIcon fill={ theme.palette.text.primary } />
                </Box>
                <SectionApplyButton direction='column' />
            </Box>

        </HomePaperPad>
    </Box>;
};

export default Sidebar1;
