import React, { FunctionComponent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';
import Box from '@mui/material/Box';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import MarkdownTable from '../../components/MarkdownTable';
import { InfoTextWithTables } from '../../types/Content';

const styles = {
    item: {
        marginBottom: '50px',
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    buttonContainer: {
        marginBottom: '30px',
    },
    notes: (theme: Theme) => ({
        ...theme.typography.body2,
        lineHeight: '18px',
        color: theme.palette.info.main,
    }),
};

const InfoTextWithTables: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: {
        items,
        hasApplyButton,
        notes,
    } } = props as { data: InfoTextWithTables };

    return <React.Fragment>
            { items.map((item) => (
                <Box key={ item.id } sx={ styles.item } >
                    { item.type === 'text' && <React.Fragment>
                        <SectionTitle title={ item.title } />
                        <Markdown text={ item.text } />
                    </React.Fragment> }

                    { item.type === 'table' && <React.Fragment>
                        <Markdown text={ item.text } />
                        <MarkdownTable items={ item.tables } />
                    </React.Fragment> }
                </Box>
            ))}

        { hasApplyButton && <Box sx={ styles.buttonContainer }>
            <SectionDivider location={ 'bottom' } />
            <SectionApplyButton direction={ isMobile ? 'column' : 'row'} />
        </Box> }

        <Box sx={ styles.notes }>{ notes }</Box>
    </React.Fragment>;
};

export default InfoTextWithTables;
