import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LibraryIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 18 18'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M6.43125 10.125H10.65V9H6.43125V10.125ZM6.43125 8.4375H13.8375V7.3125H6.43125V8.4375ZM6.43125 6.75H13.8375V5.625H6.43125V6.75ZM4.875 14.25C4.575 14.25 4.3125 14.1375 4.0875 13.9125C3.8625 13.6875 3.75 13.425 3.75 13.125V2.625C3.75 2.325 3.8625 2.0625 4.0875 1.8375C4.3125 1.6125 4.575 1.5 4.875 1.5H15.375C15.675 1.5 15.9375 1.6125 16.1625 1.8375C16.3875 2.0625 16.5 2.325 16.5 2.625V13.125C16.5 13.425 16.3875 13.6875 16.1625 13.9125C15.9375 14.1375 15.675 14.25 15.375 14.25H4.875ZM4.875 13.125H15.375V2.625H4.875V13.125ZM2.625 16.5C2.325 16.5 2.0625 16.3875 1.8375 16.1625C1.6125 15.9375 1.5 15.675 1.5 15.375V3.75H2.625V15.375H14.25V16.5H2.625Z'
                }
                fill={ props.fill || theme.palette.secondary.dark }
            />
        </SvgIcon>
    );
}

export default LibraryIcon;
