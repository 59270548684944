import { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Theme, useTheme } from '@mui/material/styles';
import { InfoItemsProps } from './InfoItemsHorizontal';
import CmsIcon from './CmsIcon';
import BlockTitle from './BlockTitle';
import Markdown from './Markdown';
import { EXTRA_SMALL } from '../../../common/constants';

const styles = {
    container: (gap: number) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: `${ gap }px`,
    }),
    item: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
        },
    }),
    iconContainer: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginRight: '20px',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
            marginBottom: '10px',
        },
    }),
    icon: (theme: Theme, width: number, color?: string) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color || theme.palette.common.white,
        width: `${ width }px`,
        height: `${ width }px`,
        borderRadius: '50%',
    }),
    iconConnector: (theme: Theme, width: number, color?: string) => ({
        borderColor: color || theme.palette.common.white,
        borderWidth: '3px',
        transform: 'rotate(90deg)',
        position: 'absolute',
        width: '100%',
        top: `${ width + 20 }px`,
        left: 0,
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            display: 'none',
        },
    }),
    textContainer: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            textAlign: 'center',
        },
    }),
    title: (theme: Theme, color?: string) => ({
        color: color || theme.palette.common.white,
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '23.44px',
    }),
    text: (theme: Theme, color?: string) => ({
        color: color || theme.palette.common.white,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '24px',
    }),
};

const InfoItemsVertical: FunctionComponent<InfoItemsProps> = ({
    data,
    hasConnections,
    iconSize = 32,
    iconContainerSize = 80,
    iconColor = '#4C8332',
    iconBackgroundColor,
    textColor,
    gap = 30,
}) => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.container(gap) } >
        { data.map((item, idx) => (
            <Box key={ item.id } sx={ styles.item } >
                <Box sx={ styles.iconContainer } >
                    <Box
                        sx={ styles.icon(
                            theme,
                            iconContainerSize,
                            iconBackgroundColor,
                        ) }
                    >
                        <CmsIcon
                            name={ item.icon }
                            fill={ iconColor }
                            sx={{ fontSize: `${ iconSize }px` }}
                        />
                    </Box>
                    { hasConnections && (idx < data.length - 1) &&
                        <Divider
                            sx={ styles.iconConnector(
                                theme,
                                iconContainerSize,
                                iconBackgroundColor,
                            ) }
                        />
                    }
                </Box>
                <Box sx={ styles.textContainer }>
                    <BlockTitle
                        title={ item.title }
                        sx={{ ...styles.title(theme, textColor) }}
                    />
                    <Markdown
                        text={ item.text }
                        sx={{ ...styles.text(theme, textColor) }}
                    />
                </Box>
            </Box>
        )) }
    </Box>;
};

export default InfoItemsVertical;
