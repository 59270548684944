import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';

const styles = {
    title: (theme: Theme): SxProps => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
    }),
};

type SectionTitleProps = { title: string; sx?: SxProps };

const BlockTitle: FunctionComponent<SectionTitleProps> = props => {
    return <Typography
        component="h4"
        sx={ theme => ({
            ...styles.title(theme),
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        }) }
    >
        { props.title }
    </Typography>;
};

export default BlockTitle;
