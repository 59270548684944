import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DoneCircleIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 30 30' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M12.7875 21.825L23.4 11.2125L21.675 9.525L12.7875 18.4125L8.2875 13.9125L6.6 15.6L12.7875 21.825ZM15 30C12.95 30 11.0125 29.6062 9.1875 28.8187C7.3625 28.0312 5.76875 26.9562 4.40625 25.5938C3.04375 24.2313 1.96875 22.6375 1.18125 20.8125C0.39375 18.9875 0 17.05 0 15C0 12.925 0.39375 10.975 1.18125 9.15C1.96875 7.325 3.04375 5.7375 4.40625 4.3875C5.76875 3.0375 7.3625 1.96875 9.1875 1.18125C11.0125 0.39375 12.95 0 15 0C17.075 0 19.025 0.39375 20.85 1.18125C22.675 1.96875 24.2625 3.0375 25.6125 4.3875C26.9625 5.7375 28.0312 7.325 28.8187 9.15C29.6062 10.975 30 12.925 30 15C30 17.05 29.6062 18.9875 28.8187 20.8125C28.0312 22.6375 26.9625 24.2313 25.6125 25.5938C24.2625 26.9562 22.675 28.0312 20.85 28.8187C19.025 29.6062 17.075 30 15 30ZM15 27.75C18.55 27.75 21.5625 26.5063 24.0375 24.0188C26.5125 21.5313 27.75 18.525 27.75 15C27.75 11.45 26.5125 8.4375 24.0375 5.9625C21.5625 3.4875 18.55 2.25 15 2.25C11.475 2.25 8.46875 3.4875 5.98125 5.9625C3.49375 8.4375 2.25 11.45 2.25 15C2.25 18.525 3.49375 21.5313 5.98125 24.0188C8.46875 26.5063 11.475 27.75 15 27.75Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default DoneCircleIcon;
