import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SectionProps } from '../../types/Section';
import { SidebarTitle } from '../../types/Sidebar';
import { KnownBreakpoints } from '../../../../common/constants';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            display: 'none',
        },
    }),
    title: (theme: Theme) => ({
        color: theme.palette.common.white,
        fontSize: '24px',
        fontWeight: 500,
        marginTop: '-5px',
    }),
};

const SidebarTitle: React.FunctionComponent<SectionProps> = (props) => {
    const { data } = props as { data: SidebarTitle };

    return (
        <Box sx={ styles.container }>
            <Typography component="h5" sx={ styles.title }>
                { data.title }
            </Typography>
        </Box>
    );
};

export default SidebarTitle;
