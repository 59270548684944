import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { HeaderHome } from '../../types/Header';
import SecureIcon from '../../../../assets/SvgIcons/SecureIcon';
import {
    KnownBreakpoints,
    SUB_LAPTOP,
    SMALL,
    EXTRA_SMALL,
} from '../../../../common/constants';
import InfoItemsVertical from '../../components/InfoItemsVertical';
import CmsIcon from '../../components/CmsIcon';
import Markdown from '../../components/Markdown';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';

const styles = {
    container: {
        width: '100%',
        position: 'relative',
        paddingBottom: '150px',
    },
    content: (theme: Theme) => ({
        display: 'flex',
        border: '1px solid white',
        borderRadius: '20px',
        padding: '55px 40px 95px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            border: 'none',
            padding: '0 0 20px 0',
        },
    }),
    left: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        flex: '1 0 760px',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            flex: '1 1 100%',
        },
    }),
    title: (theme: Theme) => ({
        fontSize: '70px',
        fontWeight: 700,
        lineHeight: '82px',
        color: theme.palette.common.white,
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            fontSize: '58px',
            fontWeight: 600,
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: '38px',
        },
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            textAlign: 'center',
        },
    }),
    text: (theme: Theme) => ({
        fontSize: '70px',
        fontWeight: 400,
        lineHeight: '82px',
        color: theme.palette.common.white,
        maxWidth: '650px',
        marginBottom: '30px',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            fontSize: '58px',
            fontWeight: 300,
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '28px',
            fontWeight: 600,
            lineHeight: '38px',
        },
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            textAlign: 'center',
        },
    }),
    items: (theme: Theme) => ({
        display: 'flex',
        gap: '30px',
        flexWrap: 'wrap',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            gap: '20px',
        },
    }),
    item: (theme: Theme) => ({
        display: 'flex',
        gap: '12px',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            gap: '9px',
        },
    }),
    itemTitle: (theme: Theme) => ({
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '22px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        [theme.breakpoints.down(SUB_LAPTOP)]: {
            fontSize: '14px',
        },
    }),
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        borderRadius: '20px',
        marginBottom: '-120px',
    },
    image: {
        borderRadius: '50%',
        maxWidth: '100%',
        height: 'auto',
    },
    buttonContainer: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '26px',
        marginTop: '-50px',
        marginLeft: '40px',
        width: '100%',
        maxWidth: '537px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            maxWidth: '350px',
            margin: '0 auto',
            gap: '21px',
        },
    }),
    button: (theme: Theme) => ({
        width: '100%',
        height: '99px',
        borderRadius: '30px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            height: '78px',
        },
    }),
    buttonText: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '22px',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '25.78px',
        },
        [theme.breakpoints.down(SMALL)]: {
            fontSize: '18px',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '21.09px',
        },
    }),
    underButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    underButtonText: (theme: Theme) => ({
        color: theme.palette.common.white,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16.41px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '14.06px',
        },
    }),
};

const HeaderHome: React.FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isSubLaptop = useMediaQuery(
        theme.breakpoints.down(SUB_LAPTOP),
    );
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data } = props as { data: HeaderHome };
    const imageAlt = data.image.data?.attributes.alternativeText || 'Welcome';

    return <Box sx={ styles.container } >
        <Box sx={ styles.content }>
            <Box sx={ styles.left }>
                <Typography component="h1" sx={ styles.title } >
                    { data.title }
                </Typography>
                <Markdown
                    text={ data.text }
                    sx={{ ...styles.text(theme) }}
                />

                { !isMobile
                    ? <Box sx={ styles.items }>
                        { data.items.map((item) => (
                            <Box key={ item.title } sx={ styles.item } >
                                <CmsIcon
                                    name={ item.icon }
                                    fill={ theme.palette.common.white }
                                    sx={{ fontSize: '21px' }}
                                />
                                <Box sx={ styles.itemTitle } >
                                    { item.title }
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    : <InfoItemsVertical
                        data={ data.items }
                        hasConnections={ true }
                        iconContainerSize={ 60 }
                        gap={ 14 }
                    />
                }
            </Box>

            { !isSubLaptop && <Box sx={ styles.right }>
                <Image
                    priority
                    alt={ imageAlt }
                    src={ data.image.data?.attributes.url || '' }
                    width={ 446 }
                    height={ 446 }
                    style={ styles.image }
                />
            </Box>}
        </Box>
        { data.hasApplyButton && <Box sx={ styles.buttonContainer }>
            <Button
                variant="contained"
                href={ NamedRoute.CREATE_APPLICATION }
                color="secondary"
                sx={ styles.button }
                data-testid="btn-apply-for-a-loan"
            >
                <Typography component="h4" sx={ styles.buttonText } >
                    Apply for a loan NOW!
                </Typography>
            </Button>
            <Box sx={styles.underButtonContainer}>
                <SecureIcon
                    fill={ theme.palette.common.white }
                    sx={{ width: '16px' }}
                />
                <Typography component="p" sx={ styles.underButtonText } >
                    Applying does NOT affect your FICO® Score!
                </Typography>
            </Box>
        </Box> }
    </Box>;
};

export default HeaderHome;
