import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BorrowIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 40 38' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M28 18C28.5667 18 29.0417 17.8083 29.425 17.425C29.8083 17.0417 30 16.5667 30 16C30 15.4333 29.8083 14.9583 29.425 14.575C29.0417 14.1917 28.5667 14 28 14C27.4333 14 26.9583 14.1917 26.575 14.575C26.1917 14.9583 26 15.4333 26 16C26 16.5667 26.1917 17.0417 26.575 17.425C26.9583 17.8083 27.4333 18 28 18ZM12 13H22V10H12V13ZM5 38C3.86667 34.2 2.75 30.4083 1.65 26.625C0.55 22.8417 0 18.9667 0 15C0 11.9333 1.06667 9.33333 3.2 7.2C5.33333 5.06667 7.93333 4 11 4H21C21.9667 2.73333 23.1417 1.75 24.525 1.05C25.9083 0.35 27.4 0 29 0C29.8333 0 30.5417 0.291667 31.125 0.875C31.7083 1.45833 32 2.16667 32 3C32 3.2 31.975 3.4 31.925 3.6C31.875 3.8 31.8167 3.98333 31.75 4.15C31.6167 4.51667 31.4917 4.89167 31.375 5.275C31.2583 5.65833 31.1667 6.05 31.1 6.45L35.65 11H40V24.95L34.35 26.8L31 38H20V34H16V38H5ZM7.25 35H13V31H23V35H28.75L31.9 24.5L37 22.75V14H34.4L28 7.6C28.0333 6.76667 28.1417 5.95833 28.325 5.175C28.5083 4.39167 28.7 3.6 28.9 2.8C27.6333 3.13333 26.4333 3.625 25.3 4.275C24.1667 4.925 23.3 5.83333 22.7 7H11C8.8 7 6.91667 7.78333 5.35 9.35C3.78333 10.9167 3 12.8 3 15C3 18.4333 3.48333 21.7917 4.45 25.075C5.41667 28.3583 6.35 31.6667 7.25 35Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default BorrowIcon;
