import { FunctionComponent } from 'react';
import { SxProps } from '@mui/material/styles';
import Divider, { DividerProps } from '@mui/material/Divider';

const styles = {
    top: { margin: '0 0 35px' },
    middle: { margin: '50px 0 60px' },
    bottom: { margin: '50px 0 30px' },
};

const getStyles = (location: keyof typeof styles): SxProps => {
    return styles[location] || styles['middle'];
};

type SectionDividerProps = DividerProps & {
    location: keyof typeof styles,
};

const SectionDivider: FunctionComponent<SectionDividerProps> = props => {
    return <Divider
        sx={ theme => ({
            ...getStyles(props.location),
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        }) }
    />;
};

export default SectionDivider;
