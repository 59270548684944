import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PhoneIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 15 15' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M4.62622 1.39359C4.94798 1.71518 5.2709 2.03586 5.59123 2.35886C6.15609 2.92863 6.15724 3.59312 5.59455 4.15852C5.19094 4.5641 4.78964 4.97201 4.37825 5.36942C4.26946 5.47423 4.26091 5.56066 4.31607 5.69279C4.58624 6.34159 4.97683 6.91608 5.4163 7.45634C6.2996 8.54213 7.29873 9.50586 8.49013 10.2537C8.74524 10.4138 9.02753 10.5305 9.29337 10.6746C9.43012 10.7488 9.52384 10.7252 9.63325 10.6121C10.0357 10.196 10.448 9.78927 10.8593 9.38166C11.3979 8.84803 12.0742 8.84689 12.6153 9.38281C13.2764 10.0376 13.9346 10.6956 14.5902 11.356C15.1401 11.9098 15.1356 12.5867 14.5826 13.1444C14.209 13.5214 13.8142 13.8793 13.4609 14.2743C12.9468 14.8491 12.3017 15.0353 11.5638 14.9947C10.4914 14.9357 9.50203 14.5799 8.54924 14.1169C6.43137 13.0878 4.62095 11.6615 3.10625 9.85873C1.98634 8.52606 1.06091 7.07553 0.452254 5.43719C0.157947 4.64478 -0.0524611 3.83335 0.0115068 2.97493C0.0510871 2.44525 0.250018 1.99473 0.640464 1.6231C1.05964 1.22389 1.45904 0.804128 1.87197 0.398185C2.41271 -0.13355 3.08903 -0.132373 3.63104 0.39959C3.96568 0.728076 4.29461 1.06192 4.62605 1.39332L4.62622 1.39359Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default PhoneIcon;
