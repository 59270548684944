import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { InfoWhy } from '../../types/Content';
import { SectionProps } from '../../types/Section';
import InfoItemsHorizontal from '../../components/InfoItemsHorizontal';
import InfoItemsVertical from '../../components/InfoItemsVertical';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';
import InfoItemsNumbered from '../../components/InfoItemsNumbered';

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemsContainer: {
        marginTop: '40px',
    },
};

const InfoWhy: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: {
        title,
        infoWithItems,
        infoWithNumberedItems,
        hasApplyButton,
    } } = props as { data: InfoWhy };

    return <Box sx={ styles.section }>
        <SectionTitle title={ title } />
        { isMobile && <SectionDivider location='top' /> }

         { !isMobile
            ? <InfoItemsHorizontal
                data={ infoWithItems.items }
                hasConnections={ infoWithItems.hasConnections }
                iconColor={ theme.palette.common.white }
            />
            : <InfoItemsVertical
                data={ infoWithItems.items }
                hasConnections={ false }
                iconSize={ 40 }
                iconColor={ theme.palette.common.white }
                iconBackgroundColor={ theme.palette.primary.main }
                textColor={ theme.palette.text.primary }
            />
        }

        <SectionDivider location='middle' />
        <SectionTitle title={ infoWithNumberedItems.title } />
        <Markdown text={ infoWithNumberedItems.text } />

        <Box sx={ styles.itemsContainer }>
            <InfoItemsNumbered
                items={ infoWithNumberedItems.items }
                hasIcons = { infoWithNumberedItems.hasItemIcons }
                icon={ infoWithNumberedItems.itemIcon }
                direction={ infoWithNumberedItems.itemsDirection }
            />
        </Box>

        { hasApplyButton && <React.Fragment>
            <SectionDivider location='bottom' />
            <SectionApplyButton direction={ isMobile ? 'column' : 'row'} />
        </React.Fragment> }
    </Box>;
};

export default InfoWhy;
