import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import SecureIcon from '../../../assets/SvgIcons/SecureIcon';
import { KnownBreakpoints, SMALL } from '../../../common/constants';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import { isLight } from '../../../common/helpers';
import { ItemsDirection } from '../types/Block';

const styles = {
    buttonContainer: (theme: Theme, direction: ItemsDirection) => ({
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        gap: '35px',
        width: '100%',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            gap: '25px',
        },
    }),
    button: (theme: Theme) => ({
        width: '100%',
        maxWidth: '258px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            maxWidth: '310px',
        },
    }),
    buttonText: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16.41px',
    }),
    underButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    underButtonText: (theme: Theme) => ({
        color: theme.palette.text.primary,
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '11.72px',
    }),
};

type SectionApplyButtonProps = { direction?: ItemsDirection };

const SectionApplyButton: FunctionComponent<SectionApplyButtonProps> = ({
    direction = 'row',
}) => {
    const theme = useTheme<Theme>();
    const isSmall = useMediaQuery(theme.breakpoints.down(SMALL));
    const buttonTitle = `Apply for a loan ${ isSmall ? '' : 'NOW!' }`;

    return <Box sx={ styles.buttonContainer(theme, direction) }>
        <Button
            variant="contained"
            href={ NamedRoute.CREATE_APPLICATION }
            color={ isLight(theme) ? 'secondary': 'primary' }
            sx={ styles.button }
            data-testid="btn-apply-for-a-loan"
        >
            <Typography component="h4" sx={ styles.buttonText }>
                { buttonTitle }
            </Typography>
        </Button>
        <Box sx={ styles.underButtonContainer }>
            <SecureIcon
                fill={ theme.palette.text.primary }
                sx={{ width: '16px' }}
            />
            <Typography component="p" sx={ styles.underButtonText }>
                Applying does NOT affect your FICO® Score!
            </Typography>
        </Box>
    </Box>;
};

export default SectionApplyButton;
