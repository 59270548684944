import { useConfig } from './ConfigProvider';
import replaceVars from '../helpers/replaceVars';

/**
 * Returns a function that replaces CMS variables in a text string
 *
 * @returns string
 */
export default function useReplaceCmsVars(): (text: string) => string {
    const { consumerOperationsDetails } = useConfig();

    // Set variables for replacement in text from CMS here:
    const vars = {
        supportPhoneNumber: consumerOperationsDetails?.phoneNumber || '',
        supportEmail: consumerOperationsDetails?.supportEmail || '',
        currentYear: new Date().getFullYear(),
    };

    return (text: string) => replaceVars(text, vars);
}
