import { FunctionComponent } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import FacebookIcon from '../../../assets/SvgIcons/external/FacebookIcon';
import InstagramIcon from '../../../assets/SvgIcons/external/InstagramIcon';
import EmailIcon from '../../../assets/SvgIcons/external/EmailIcon';
import PhoneIcon from '../../../assets/SvgIcons/external/ContactPhoneIcon';

const CmsExternalIconMap = {
    facebook: (props: SvgIconProps) => <FacebookIcon { ...props } />,
    instagram: (props: SvgIconProps) => <InstagramIcon { ...props } />,
    email: (props: SvgIconProps) => <EmailIcon { ...props } />,
    contact_phone: (props: SvgIconProps) => <PhoneIcon { ...props } />,
};

export type CmsExternalIconType = keyof typeof CmsExternalIconMap;

type CmsExternalIconProps = SvgIconProps & {
    name: CmsExternalIconType;
};

const CmsExternalIcon: FunctionComponent<CmsExternalIconProps> = props => {
    if (!CmsExternalIconMap[props.name]) {
        return <></>;
    }

    return (CmsExternalIconMap[props.name])(props);
};

export default CmsExternalIcon;
