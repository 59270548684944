/* eslint-disable no-useless-escape */
export type TextVariables = {
    [name: string]: string | number;
};

/**
 * Modifies given text replacing given text variables in it.
 * Variables in the CMS must be in format {{variableName}} because
 * The Markdown parser will not parse them correctly as HTML <a> tags.
 *
 * @param {string} template
 * @param {TextVariables} variables
 * @return {string}
 * @private
 */
const replaceVars = (text: string, variables: TextVariables): string => {
    if (!text) {
        return text;
    }

    for (const varName of Object.keys(variables)) {
        const value = variables[varName];
        text = text.replace(
            new RegExp(`{{${ varName }}}`, 'gmi'),
            value.toString(),
        );
    }

    return text;
};

export default replaceVars;
