import { FunctionComponent, PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import WarningIcon from '../../../assets/SvgIcons/WarningIcon';
import InfoItemIconPad from './InfoItemIconPad';

const styles = {
    number: (theme: Theme) => ({
        color: theme.palette.common.white,
    }),
    warning: (theme: Theme) => ({
        fill: theme.palette.common.white,
        fontSize: '18px',
        marginTop: '-4px',
    }),
};

const InfoIconMap = {
    number: (props: PropsWithChildren) => <Box sx={ styles.number }>
            { props.children }
        </Box>,
    warning: () => <WarningIcon sx={ styles.warning } />,
};

export type InfoItemIconType = keyof typeof InfoIconMap;
export type InfoItemIconProps = PropsWithChildren & { icon: InfoItemIconType };

const InfoItemIcon: FunctionComponent<InfoItemIconProps> = props => {
    const func = InfoIconMap[props.icon] || InfoIconMap['number'];

    return <InfoItemIconPad icon={ props.icon }>
        { func(props) }
    </InfoItemIconPad>;
};

export default InfoItemIcon;
