import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { KnownBreakpoints } from '../../../common/constants';
import CmsIcon from './CmsIcon';
import { BlockFAQ } from '../types/Block';
import BlockTitle from './BlockTitle';
import Markdown from './Markdown';
import { HEADER_BOTTOM_HEIGHT } from '../../../config';

const styles = {
    block: {
        display: 'flex',
        flexDirection: 'column',
    },
    head: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '24px',
    },
    icon: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        width: '36px',
        height: '36px',
        borderRadius: '18px',
    }),
    summary: (theme: Theme) => ({
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '42px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontWeight: 500,
            lineHeight: '16px',
        },
    }),
    detailsElement: {
        padding: '50px 0 0 50px',
        marginBottom: '-160px',
    },
};

type BlockFAQProps = { block: BlockFAQ };

const BlockFAQ: React.FunctionComponent<BlockFAQProps> = ({ block }) => {
    const theme = useTheme<Theme>();
    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    const [headerHeight, setHeaderHeight] = useState(HEADER_BOTTOM_HEIGHT);


    useEffect(() => {
        window.onhashchange = function() {
            const hash = location.hash;
            const id = hash.startsWith('#') ? hash.substring(1) : hash;
            if (id && expandedIds.indexOf(id) === -1) {
                setExpandedIds([...expandedIds, id]);
            }
        };

        const headerTop = document.getElementById('header-top');
        const headerTopHeight = headerTop
            ? headerTop.offsetHeight
            : HEADER_BOTTOM_HEIGHT;
        setHeaderHeight(() => (headerTopHeight));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Box sx={ styles.block }>
        { (block.icon || block.title) && <Box sx={ styles.head }>
            { block.icon && <Box sx={ styles.icon }>
                <CmsIcon
                    name={ block.icon }
                    fill={ theme.palette.common.white }
                    sx={{ fontSize: '20px' }}
                />
            </Box> }
            { block.title && <BlockTitle title={ block.title } /> }
        </Box> }

        <Box>
            { block.items.map(item => {
                const id = item.summaryId || String(item.id);

                return <Accordion
                    key={ item.id }
                    expanded={ expandedIds.indexOf(id) !== -1 }
                    onChange={ (_, expanded) => {
                        if (expanded) {
                            setExpandedIds([...expandedIds, id]);
                        } else {
                            setExpandedIds(expandedIds.filter(p => p !== id));
                        }
                    }}
                >
                    <AccordionSummary
                        aria-controls={ `panel${ item.id }-content` }
                        id={ id }
                        sx={{ scrollMarginTop: `${ headerHeight }px` }}
                    >
                        <Typography component="h2" sx={ styles.summary }>
                            { item.summary }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { typeof item.details === 'string' &&
                            <Markdown text={ item.details } />
                        }
                        { React.isValidElement(item.details) &&
                            <Box sx={ styles.detailsElement }>
                                { item.details }
                            </Box>
                        }
                    </AccordionDetails>
                </Accordion>;
            })}
        </Box>
    </Box>;
};

export default BlockFAQ;
