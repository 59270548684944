import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FaceIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 30 30' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M20.475 13.0125C21.05 13.0125 21.5312 12.8125 21.9187 12.4125C22.3062 12.0125 22.5 11.5375 22.5 10.9875C22.5 10.4125 22.3062 9.93125 21.9187 9.54375C21.5312 9.15625 21.05 8.9625 20.475 8.9625C19.925 8.9625 19.45 9.15625 19.05 9.54375C18.65 9.93125 18.45 10.4125 18.45 10.9875C18.45 11.5375 18.65 12.0125 19.05 12.4125C19.45 12.8125 19.925 13.0125 20.475 13.0125ZM9.525 13.0125C10.1 13.0125 10.5813 12.8125 10.9688 12.4125C11.3562 12.0125 11.55 11.5375 11.55 10.9875C11.55 10.4125 11.3562 9.93125 10.9688 9.54375C10.5813 9.15625 10.1 8.9625 9.525 8.9625C8.975 8.9625 8.5 9.15625 8.1 9.54375C7.7 9.93125 7.5 10.4125 7.5 10.9875C7.5 11.5375 7.7 12.0125 8.1 12.4125C8.5 12.8125 8.975 13.0125 9.525 13.0125ZM15 23.2125C16.65 23.2125 18.1688 22.7687 19.5563 21.8812C20.9438 20.9937 21.95 19.7875 22.575 18.2625H7.425C8.075 19.7875 9.0875 20.9937 10.4625 21.8812C11.8375 22.7687 13.35 23.2125 15 23.2125ZM15 30C12.925 30 10.975 29.6062 9.15 28.8187C7.325 28.0312 5.7375 26.9625 4.3875 25.6125C3.0375 24.2625 1.96875 22.675 1.18125 20.85C0.39375 19.025 0 17.075 0 15C0 12.925 0.39375 10.975 1.18125 9.15C1.96875 7.325 3.0375 5.7375 4.3875 4.3875C5.7375 3.0375 7.325 1.96875 9.15 1.18125C10.975 0.39375 12.925 0 15 0C17.075 0 19.025 0.39375 20.85 1.18125C22.675 1.96875 24.2625 3.0375 25.6125 4.3875C26.9625 5.7375 28.0312 7.325 28.8187 9.15C29.6062 10.975 30 12.925 30 15C30 17.075 29.6062 19.025 28.8187 20.85C28.0312 22.675 26.9625 24.2625 25.6125 25.6125C24.2625 26.9625 22.675 28.0312 20.85 28.8187C19.025 29.6062 17.075 30 15 30ZM15 27.75C18.55 27.75 21.5625 26.5125 24.0375 24.0375C26.5125 21.5625 27.75 18.55 27.75 15C27.75 11.45 26.5125 8.4375 24.0375 5.9625C21.5625 3.4875 18.55 2.25 15 2.25C11.45 2.25 8.4375 3.4875 5.9625 5.9625C3.4875 8.4375 2.25 11.45 2.25 15C2.25 18.55 3.4875 21.5625 5.9625 24.0375C8.4375 26.5125 11.45 27.75 15 27.75Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default FaceIcon;
