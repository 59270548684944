import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import CmsIcon from '../../components/CmsIcon';
import { ContactUs } from '../../types/Content';
import { SectionProps } from '../../types/Section';
import React from 'react';
import { isLight } from '../../../../common/helpers';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import BlockTitle from '../../components/BlockTitle';
import SectionDivider from '../../components/SectionDivider';

const styles = {
    title: (theme: Theme) => ({
        ...theme.typography.h2,
        color: theme.palette.text.primary,
        lineHeight: '48.05px',
    }),
    blocks: (theme: Theme) => ({
        display: 'flex',
        gap: '65px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            flexWrap: 'wrap',
        },
    }),
    block: (theme: Theme) => ({
        width: '50%',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '100%',
        },
    }),
    subtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '20px',
    },
    iconContainer: (theme: Theme) => ({
        width: '36px',
        height: '36px',
        borderRadius: '50px',
        backgroundColor: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    text: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '24px',
    }),
};

const ContactUs: React.FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const { data } = props as { data: ContactUs };

    return <React.Fragment>
        <SectionTitle title={ data.title } />
        <SectionDivider location='top' />

        <Box sx={ styles.blocks }>
            { data.blocks.map(block => (<Box
                key={ block.id }
                sx={ styles.block }
            >
                <Box sx={ styles.subtitleContainer }>
                    <Box sx={ styles.iconContainer }>
                        <CmsIcon
                            name={ block.icon }
                            sx={{ fontSize: '17px' }}
                            fill={ theme.palette.common.white }
                        />
                    </Box>
                    <BlockTitle title={ block.title } />
                </Box>
                <Markdown text={ block.text } sx={{ ...styles.text(theme) }} />
            </Box> ))}
        </Box>
    </React.Fragment>;
};

export default ContactUs;
