import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';
import { KnownBreakpoints } from '../../../common/constants';
import useReplaceCmsVars from '../../../common/hooks/useReplaceCmsVars';

const styles = {
    text: (theme: Theme): SxProps => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '24px',
        '& > p > code': {
            color: theme.palette.error.light,
            fontWeight: 800,
        },
        '& > p > strong > code': {
            color: theme.palette.error.light,
            fontWeight: 800,
        },
        '& > p > a, li > a': {
            color: '#68A34C',
            fontWeight: 700,
            textDecoration: 'underline',
        },
        '& > h1': {
            ...theme.typography.h1,
            marginBottom: '20px',
        },
        '& > h2': {
            ...theme.typography.h2,
            marginBottom: '20px',
        },
        '& > h3': {
            ...theme.typography.h3,
            marginBottom: '20px',
        },
        '& > h4': {
            ...theme.typography.h4,
            lineHeight: '28.13px',
            marginBottom: '20px',
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '21.09px',
            },
        },
        '& > h5': {
            ...theme.typography.h5,
            marginBottom: '20px',
        },
        '& > h6': {
            ...theme.typography.h6,
            marginBottom: '20px',
        },
    }),
};

type MarkdownProps = { text: string; sx?: SxProps };

const Markdown: FunctionComponent<MarkdownProps> = props => {
    const replaceCmsVars = useReplaceCmsVars();
    const text = replaceCmsVars(props.text);

    return <Typography
        component="span"
        sx={ theme => ({
            ...styles.text(theme),
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        }) }
    >
        <ReactMarkdown
            remarkPlugins={ [gfm] }
            components={{
                a: ({ node, ...props }) => {
                    return <a
                        href={
                            props.href
                            || ('tel:' + (node?.children[0] as any).value || '')
                        }
                    >
                        { props.children }
                    </a>;
                },
            }}
        >
            { text }
        </ReactMarkdown>
    </Typography>;
};

export default Markdown;
