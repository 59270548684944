import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LocationIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 16 20' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M8.00222 9.75C8.48407 9.75 8.89583 9.57843 9.2375 9.23528C9.57917 8.89214 9.75 8.47964 9.75 7.99778C9.75 7.51593 9.57843 7.10417 9.23528 6.7625C8.89214 6.42083 8.47964 6.25 7.99778 6.25C7.51593 6.25 7.10417 6.42158 6.7625 6.76472C6.42083 7.10786 6.25 7.52036 6.25 8.00222C6.25 8.48407 6.42158 8.89583 6.76472 9.2375C7.10786 9.57917 7.52036 9.75 8.00222 9.75ZM8 18.025C10.2167 16.0083 11.8542 14.1792 12.9125 12.5375C13.9708 10.8958 14.5 9.45 14.5 8.2C14.5 6.23683 13.8726 4.62938 12.6177 3.37763C11.3629 2.12588 9.82362 1.5 8 1.5C6.17638 1.5 4.63715 2.12588 3.3823 3.37763C2.12743 4.62938 1.5 6.23683 1.5 8.2C1.5 9.45 2.04167 10.8958 3.125 12.5375C4.20833 14.1792 5.83333 16.0083 8 18.025ZM8 20C5.31667 17.7167 3.3125 15.5958 1.9875 13.6375C0.6625 11.6792 0 9.86667 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.86667 15.3375 11.6792 14.0125 13.6375C12.6875 15.5958 10.6833 17.7167 8 20Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default LocationIcon;
