import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ExtraMoneyIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 34 26' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M21.0455 13.0625C19.9091 13.0625 18.9432 12.6615 18.1477 11.8594C17.3523 11.0573 16.9545 10.0833 16.9545 8.9375C16.9545 7.79167 17.3523 6.81771 18.1477 6.01562C18.9432 5.21354 19.9091 4.8125 21.0455 4.8125C22.1818 4.8125 23.1477 5.21354 23.9432 6.01562C24.7386 6.81771 25.1364 7.79167 25.1364 8.9375C25.1364 10.0833 24.7386 11.0573 23.9432 11.8594C23.1477 12.6615 22.1818 13.0625 21.0455 13.0625ZM10.1364 17.875C9.56818 17.875 9.08523 17.6745 8.6875 17.2734C8.28977 16.8724 8.09091 16.3854 8.09091 15.8125V2.0625C8.09091 1.48958 8.28977 1.0026 8.6875 0.601562C9.08523 0.200521 9.56818 0 10.1364 0H31.9545C32.5227 0 33.0057 0.200521 33.4034 0.601562C33.8011 1.0026 34 1.48958 34 2.0625V15.8125C34 16.3854 33.8011 16.8724 33.4034 17.2734C33.0057 17.6745 32.5227 17.875 31.9545 17.875H10.1364ZM13.5455 15.8125H28.5455C28.5455 14.85 28.875 14.0365 29.5341 13.3719C30.1932 12.7073 31 12.375 31.9545 12.375V5.5C31 5.5 30.1932 5.16771 29.5341 4.50313C28.875 3.83854 28.5455 3.025 28.5455 2.0625H13.5455C13.5455 3.025 13.2159 3.83854 12.5568 4.50313C11.8977 5.16771 11.0909 5.5 10.1364 5.5V12.375C11.0909 12.375 11.8977 12.7073 12.5568 13.3719C13.2159 14.0365 13.5455 14.85 13.5455 15.8125ZM29.9091 22H6.04545C5.47727 22 4.99432 21.7995 4.59659 21.3984C4.19886 20.9974 4 20.5104 4 19.9375V4.125H6.04545V19.9375H29.9091V22Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
            <path
                d={
                    'M25.9091 25.875H2.04545C1.47727 25.875 0.994318 25.6745 0.596591 25.2734C0.198864 24.8724 0 24.3854 0 23.8125V8H2.04545V23.8125H25.9091V25.875Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default ExtraMoneyIcon;
