import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Markdown from '../Markdown';
import { LoyaltyStatus } from '../../types/Block';
import SectionTitle from '../SectionTitle';
import SectionDivider from '../SectionDivider';
import LoyaltyStatusRoadmap from './LoyaltyStatusRoadmap';

const styles = {
    textContainer: {
        marginBottom: '20px',
    },
};

const LoyaltyStatusBlock: FunctionComponent<LoyaltyStatus> = props => {
    return <React.Fragment>
        <SectionTitle title={ props.title } />
        <SectionDivider location={ 'top' } />
        { props.text && <Box sx={ styles.textContainer }>
            <Markdown text={ props.text } />
        </Box> }

        <LoyaltyStatusRoadmap items={ props.items } />

    </React.Fragment>;
};

export default LoyaltyStatusBlock;
