import React from 'react';
import Box from '@mui/material/Box';
import { SectionProps } from '../../types/Section';
import { FAQ } from '../../types/Content';
import BlockFAQ from '../../components/BlockFAQ';
import { CmsIconType } from '../../components/CmsIcon';
import MapIcon from '../../../../assets/SvgIcons/MapIcon';
import { SvgIconProps } from '@mui/material';
import SeeMoreButton from '../../components/SeeMoreButton';
import SectionTitle from '../../components/SectionTitle';
import SectionDivider from '../../components/SectionDivider';

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    blocks: {
        display: 'flex',
        flexDirection: 'column',
        gap: '60px',
        marginBottom: '30px',
    },
};

const Map: React.FunctionComponent<SvgIconProps> = () => {
    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '150px',
        width: '100%',
    }}>
        <MapIcon sx={{ width: '100%', height: '100%' }} />
    </Box>;
};

const mapBlock = {
    id: Math.floor(Math.random()),
    icon: 'location' as CmsIconType,
    title: 'Other questions',
    items: [{
        id: 1,
        summary: 'In which states does the company provide lending services?',
        details: <Map />,
        summaryId: 'map',
    }],
};

const FAQ: React.FunctionComponent<SectionProps> = props => {
    const { data } = props as { data: FAQ };
    const blocks = data.hasMap ? [...data.blocks, mapBlock] : data.blocks;

    return <Box sx={ styles.section }>
        <SectionTitle title={ data.title } />
        { data.hasMap && <SectionDivider location='top' /> }

        <Box sx={ styles.blocks }> {
            blocks.map(block => <BlockFAQ key={ block.id } block={ block } />)
        } </Box>

        { data.hasSeeMoreButton && <Box>
            <SeeMoreButton href='/faq'>
                See all questions
            </SeeMoreButton>
        </Box> }
    </Box>;
};

export default FAQ;
