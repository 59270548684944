import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BusinessIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 40 38' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M3 38C2.2 38 1.5 37.7 0.9 37.1C0.3 36.5 0 35.8 0 35V10.55C0 9.75 0.3 9.05 0.9 8.45C1.5 7.85 2.2 7.55 3 7.55H12.5V3C12.5 2.2 12.8 1.5 13.4 0.9C14 0.3 14.7 0 15.5 0H24.5C25.3 0 26 0.3 26.6 0.9C27.2 1.5 27.5 2.2 27.5 3V7.55H37C37.8 7.55 38.5 7.85 39.1 8.45C39.7 9.05 40 9.75 40 10.55V35C40 35.8 39.7 36.5 39.1 37.1C38.5 37.7 37.8 38 37 38H3ZM15.5 7.55H24.5V3H15.5V7.55ZM37 25.45H24.55V28.45H15.55V25.45H3V35H37V25.45ZM18.55 25.45H21.55V22.45H18.55V25.45ZM3 22.45H15.55V19.45H24.55V22.45H37V10.55H3V22.45Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default BusinessIcon;
