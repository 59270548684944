import { FC } from 'react';
import BaseLayout , { ContentLayoutProps } from '../BaseLayout';

const StaticPageLayout: FC<ContentLayoutProps> = props => {
    return <BaseLayout { ...props }>
        { props.children }
    </BaseLayout>;
};

export default StaticPageLayout;
