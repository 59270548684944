import { FunctionComponent } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { isLight } from '../../../common/helpers';
import { InfoItemIconType, InfoItemIconProps } from './InfoItemIcon';

const getColor = (theme: Theme, icon: InfoItemIconType): string => {
    const itemIconMap = {
        number: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.primary.dark
        ,
        warning: theme.palette.loyalty.gold,
    };

    return itemIconMap[icon] || itemIconMap['number'];
};

const styles = {
    iconPad: (theme: Theme, icon: InfoItemIconType) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        backgroundColor: getColor(theme, icon),
    }),
};

const InfoItemIconPad: FunctionComponent<InfoItemIconProps> = props => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.iconPad(theme, props.icon) }>
        { props.children }
    </Box>;
};

export default InfoItemIconPad;
