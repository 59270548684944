import Box from '@mui/material/Box';
import Section from './Section';
import { FooterData } from './types/Footer';

const styles = {
    container: {
        margin: '40px 0',
    },
};

type FooterProps = { data: FooterData[], withoutThemeSwitcher?: boolean };

const Footer: React.FunctionComponent<FooterProps> = (
    { data, withoutThemeSwitcher },
) => {
    return <Box sx={ styles.container }>
        { data.map((item, idx) => {
            return <Section
                key={ idx }
                data={ item }
                withoutThemeSwitcher={ withoutThemeSwitcher }
            />;
        }) }
    </Box>;
};

export default Footer;
