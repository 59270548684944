import React, { FunctionComponent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';
import { InfoBlocks } from '../../types/Content';
import InfoBlock from '../../components/InfoBlock';

const InfoBlocks: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: { blocks, hasApplyButton } } = props as { data: InfoBlocks };

    return <React.Fragment>
        { blocks.map((block, idx) => (
            <React.Fragment key={ block.id }>
                <InfoBlock block={ block } />
                <SectionDivider
                    location={ (idx < blocks.length - 1) ? 'middle' : 'bottom' }
                />
            </React.Fragment>
        )) }

        { hasApplyButton && <React.Fragment>
            <SectionApplyButton direction={ isMobile ? 'column' : 'row'} />
        </React.Fragment> }
    </React.Fragment>;
};

export default InfoBlocks;
