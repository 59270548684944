import React, { FunctionComponent } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Markdown from '../Markdown';
import { LoyaltyPoints } from '../../types/Block';
import SectionTitle from '../SectionTitle';
import CmsComboIcon from '../CmsComboIcon';
import { EXTRA_SMALL, KnownBreakpoints } from '../../../../common/constants';

const styles = {
    text: {
        marginBottom: '40px',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px',
    },
    item: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'baseline',
        flexGrow: 1,
        flexBasis: '48%',
        gap: '10px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            flexBasis: '100%',
        },
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }),
};

const LoyaltyPointsBlock: FunctionComponent<LoyaltyPoints> = props => {
    const theme = useTheme<Theme>();

    return <React.Fragment>
        <SectionTitle title={ props.title } />

        { props.text && <Box sx={ styles.text }>
            <Markdown text={ props.text } />
        </Box> }

        <Box sx={ styles.container }>
            { props.items.map((item) => (
                <Box key={ item.id } sx={ styles.item }>
                    <CmsComboIcon
                        icon={ item.icon }
                        points={ item.points }
                        fill={ theme.palette.common.white }
                    />
                    <Box>{ item.title }</Box>
                </Box>
            )) }
        </Box>
    </React.Fragment>;
};

export default LoyaltyPointsBlock;
