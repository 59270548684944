import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { KnownBreakpoints, SMALL } from '../../../../common/constants';
import { Header } from '../../types/Header';
import { SectionProps } from '../../types/Section';

const styles = {
    content: {
        display: 'flex',
        paddingBottom: '150px',
    },
    title: (theme: Theme) => ({
        fontSize: '60px',
        fontWeight: 500,
        lineHeight: '82px',
        color: theme.palette.common.white,
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '44px',
            fontWeight: 400,
            lineHeight: '54px',
        },
        [theme.breakpoints.down(SMALL)]: {
            fontSize: '32px',
            textAlign: 'center',
        },
    }),
};

const Header: React.FunctionComponent<SectionProps> = props => {
    const { data } = props as { data: Header };

    return <Box sx={ styles.content } >
        <Typography component="h1" sx={ styles.title } >
            { data.title }
        </Typography>
    </Box>;
};

export default Header;
