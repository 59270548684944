import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { ItemRegular } from '../types/Item';
import CmsIcon from './CmsIcon';
import BlockTitle from './BlockTitle';
import Markdown from './Markdown';
import { FC } from 'react';

const styles = {
    container: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
    },
    iconsRow: {
        marginBottom: '23px',
        display: 'flex',
        width: '100%',
        position: 'relative',
    },
    item: {
        display: 'flex',
        flexGrow: 1,
    },
    iconContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    icon: (theme: Theme, color?: string) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color || theme.palette.primary.main,
        width: '80px',
        height: '80px',
        borderRadius: '40px',
        zIndex: 100,
    }),
    dividerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 15%',
        transform: 'translateY(-65px)',
        zIndex: 1,
    },
    textItemsRow: {
        display: 'flex',
        width: '100%',
    },
    textColumn: (items: number) => ({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: `calc(100% / ${items})`,
        padding: '0 5px',
    }),
    iconConnector: (theme: Theme, color?: string) => ({
        flexGrow: 1,
        borderColor: color || theme.palette.primary.main,
        borderWidth: '3px',
    }),
    title: (color?: string) => ({
        padding: '0 14px 4px 0',
        color: color ? color : 'inherit',
    }),
    text: (color?: string) => ({
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '24px',
        color: color ? color : 'inherit',
        textAlign: 'center',
    }),
};

export type InfoItemsProps = {
    data: ItemRegular[],
    hasConnections: boolean,
    iconSize?: number,
    iconContainerSize?: number,
    iconColor?: string;
    iconBackgroundColor?: string;
    textColor?: string;
    gap?: number;
};

const InfoItemsHorizontal: FC<InfoItemsProps> = ({
    data,
    hasConnections,
    iconSize = 32,
    iconColor = '#4C8332',
    iconBackgroundColor,
    textColor,
}) => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.container } >
        <Box>
            <Box sx={ styles.iconsRow }>
                { data.map((item) => (
                    <Box key={ item.id } sx={ styles.item } >
                            <Box sx={ styles.iconContainer }>
                                <Box
                                    sx={ styles.icon(
                                        theme,
                                        iconBackgroundColor,
                                    ) }
                                >
                                    <CmsIcon
                                        name={ item.icon }
                                        fill={ iconColor }
                                        sx={{ fontSize: `${ iconSize }px` }}
                                    />
                                </Box>
                            </Box>
                    </Box>
                )) }
            </Box>
            { hasConnections && <Box sx={ styles.dividerContainer }>
                <Divider
                    sx={ styles.iconConnector(
                        theme,
                        iconBackgroundColor,
                    ) }
                />
            </Box> }
        </Box>
        <Box sx={ styles.textItemsRow }>
            { data.map((item, idx) => (
                <Box key={ idx } sx={ styles.textColumn(data.length) }>
                    <BlockTitle
                        title={ item.title }
                        sx={{ ...styles.title(textColor) }}
                    />
                    <Markdown
                        text={ item.text }
                        sx={{ ...styles.text(textColor) }}
                    />
                </Box>
            )) }
        </Box>
    </Box>;
};

export default InfoItemsHorizontal;
