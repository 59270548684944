import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FacebookIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 12 22' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M0.798198 11.7172H3.20975V21.6452C3.20975 21.8412 3.36858 22 3.56459 22H7.65347C7.84948 22 8.00831 21.8412 8.00831 21.6452V11.764H10.7806C10.9608 11.764 11.1125 11.6287 11.1331 11.4497L11.5541 7.79467C11.5657 7.69411 11.5338 7.59341 11.4666 7.51797C11.3992 7.44246 11.3028 7.39924 11.2017 7.39924H8.00845V5.10812C8.00845 4.41746 8.38032 4.06723 9.11384 4.06723C9.21838 4.06723 11.2017 4.06723 11.2017 4.06723C11.3977 4.06723 11.5566 3.90834 11.5566 3.71239V0.357465C11.5566 0.161452 11.3977 0.00262581 11.2017 0.00262581H8.32433C8.30403 0.00163226 8.25897 0 8.19254 0C7.69328 0 5.95791 0.0980064 4.58709 1.3591C3.06824 2.7566 3.27937 4.42988 3.32983 4.71999V7.39917H0.798198C0.602185 7.39917 0.443359 7.55799 0.443359 7.75401V11.3623C0.443359 11.5583 0.602185 11.7172 0.798198 11.7172Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default FacebookIcon;
