import { Fragment, FunctionComponent } from 'react';

const Trustwave: FunctionComponent = () => {
    const title = 'This site is protected by Trustwave\'s Trusted Commerce program';

    const handleClick = () => {
        window.open(
            'https://sealserver.trustwave.com/cert.php?customerId=8ec4fc3217f94dec9beaee3b3d7ce901&amp;size=105x54&amp;style=',
            'c_TW',
            'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720',
        );
        return;
    };
    const handleContextMenu = () => {
        alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark' +
        ' of TrustWave Holdings, Inc.');
        return;
    };

    return <Fragment>
        <script
            type="text/javascript"
            src="https://sealserver.trustwave.com/seal.js?code=8ec4fc3217f94dec9beaee3b3d7ce901"
            async
        />
        <img
            id="trustwaveSealImage"
            src="https://sealserver.trustwave.com/seal_image.php?customerId=8ec4fc3217f94dec9beaee3b3d7ce901&amp;size=105x54&amp;style="
            style={{ cursor: 'pointer', border: 0 }}
            width={ 76 }
            height={ 40 }
            onClick={ handleClick }
            onContextMenu={ handleContextMenu }
            alt={ title }
            title={ title }
        />
    </Fragment>;
};

export default Trustwave;
