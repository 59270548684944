import { FunctionComponent } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CmsIcon, { CmsIconType } from './CmsIcon';
import { isLight } from '../../../common/helpers';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.primary.dark
        ,
        borderRadius: '20px',
        minWidth: '80px',
        height: '86px',
    }),
    iconContainer: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${ theme.palette.common.white }`,
        width: '59px',
        padding: '16px 16px 12px',
    }),
    icon: {
        fontSize: '30px',
    },
    points: (theme: Theme) => ({
        color: theme.palette.common.white,
        padding: '4px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16.41px',
    }),
};

export type CmsComboIconProps = SvgIconProps & {
    icon: CmsIconType;
    points: string;
};

const CmsComboIcon: FunctionComponent<CmsComboIconProps> = props => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.container(theme) }>
        <Box sx={ styles.iconContainer }>
            <CmsIcon
                name={ props.icon }
                sx={{
                    ...styles.icon,
                    ...props.sx,
                }}
                fill={ props.fill }
            />
        </Box>
        <Box sx={ styles.points }> { `+${ props.points } pts` } </Box>
    </Box>;
};

export default CmsComboIcon;
