import React, { FunctionComponent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { KnownBreakpoints } from '../../../../common/constants';
import { InfoLoyalty } from '../../types/Content';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';
import LoyaltyStatusBlock from '../../components/Loyalty/LoyaltyStatusBlock';
import LoyaltyPointsBlock from '../../components/Loyalty/LoyaltyPointsBlock';

const InfoLoyalty: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: {
        loyaltyStatus,
        loyaltyPoints,
        hasApplyButton,
    } } = props as { data: InfoLoyalty };

    return <React.Fragment>
        <LoyaltyStatusBlock { ...loyaltyStatus } />
        <SectionDivider location={ 'middle' } />
        <LoyaltyPointsBlock { ...loyaltyPoints } />

        { hasApplyButton && <React.Fragment>
            <SectionDivider location={ 'bottom' } />
            <SectionApplyButton direction={ isMobile ? 'column' : 'row'} />
        </React.Fragment> }
    </React.Fragment>;
};

export default InfoLoyalty;
