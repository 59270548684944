import { FC, MouseEvent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { useRouter } from 'next/router';

const styles = {
    button: (theme: Theme) => ({
        backgroundColor: theme.palette.info.light,
        padding: '0 18px',
        height: '40px',
        '&:hover': {
            backgroundColor: theme.palette.info.light,
            opacity: 0.9,
        },
    }),
    icon: (theme: Theme) => ({
        color: theme.palette.secondary.dark,
    }),
    title: (theme: Theme) => ({
        color: theme.palette.secondary.dark,
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '24px',
    }),
};

const SeeMoreButton: FC<ButtonProps> = props => {
    const router = useRouter();

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(e);
            return;
        }

        if (!props.href) {
            return;
        }

        router.push(props.href);
    };

    return <Button
        data-testid="btn-see-more"
        disableRipple={true}
        startIcon={
            props.startIcon || <VisibilityOutlinedIcon sx={ styles.icon } />
        }
        sx={ styles.button }
        onClick={ handleClick }
    >
        <Typography component="span" sx={ styles.title }>
            { props.children }
        </Typography>
    </Button>;
};

export default SeeMoreButton;
