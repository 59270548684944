import { FC } from 'react';

import * as Sections from './sections';
import { SectionProps } from './types/Section';

const Section: FC<SectionProps> = (
    { data, withoutThemeSwitcher },
) => {
    const DynamicSection = Sections[data.sectionType];

    if (!DynamicSection) {
        return <></>;
    }

    return <DynamicSection
        data={ data }
        withoutThemeSwitcher={ withoutThemeSwitcher }
    />;
};

export default Section;
