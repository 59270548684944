import { FC, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { ItemTable } from '../types/Item';
import { isLight } from '../../../common/helpers';
import { TABLE_BREAKPOINT } from '../../../common/constants';
import transformTable from '../../../common/helpers/transformTable';

const styles = {
    block: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
    },
    tableContainer: (theme: Theme, isHalf: boolean): SxProps => ({
        border: `1px solid ${ isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.main
        }`,
        flexBasis: isHalf ? 'calc(48%)' : 'calc(100%)',
        flexGrow: 1,
        [theme.breakpoints.down(940)]: {
            flexBasis: 'calc(100%)',
        },

        // caption / title
        '& > h5': {
            backgroundColor: isLight(theme) ? '#7899A6' : '#365B70',
            color: 'white',
            textAlign: 'center',
            borderBottom: `1px solid ${isLight(theme) ? '#BCD1D9' : '#7899A6'}`,
            padding: '15px 0',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '14px',
        },

        // table
        '& > table': {
            width: '100%',
            borderSpacing: '0',
            borderCollapse: 'collapse',

            // thead tr
            '& > thead > tr': {
                color: 'white',
                fontSize: '12px',
                backgroundColor: isLight(theme) ? '#7899A6' : '#365B70',
                '& > th': {
                    padding: '10px 15px',
                    borderRight: `1px solid ${
                        isLight(theme) ? '#BCD1D9' : '#7899A6'
                    }`,
                    '&:nth-last-type': {
                        borderRight: 0,
                    },
                    [theme.breakpoints.down(800)]: {
                        padding: '7px 4px',
                    },

                    /* hack for line break, add in markdown table ` ` */
                    '& > code::after': {
                        content: '"\\a"',
                        whiteSpace: 'pre',
                    },
                },
            },

            // tbody tr
            '& > tbody > tr': {
                borderTop: `1px solid ${ isLight(theme)
                    ? '#BCD1D9' : '#7899A6'
                }`,
                backgroundColor: isLight(theme) ? '#FFFFFF' : '#13345E',
                '&:nth-of-type(even)': {
                    backgroundColor: isLight(theme) ? '#E7ECEE' : '#0D213A',
                },

                // tbody td
                '& > td': {
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    padding: '5px',
                    borderRight: `1px solid ${
                        isLight(theme) ? '#BCD1D9' : '#7899A6'
                    }`,

                    // td::before (emulate th)
                    '&::before': {
                        backgroundColor: isLight(theme) ? '#7899A6' : '#365B70',
                        color: '#ffffff',
                    },

                    /* hack for line break, add in markdown table ` ` */
                    '& > code::after': {
                        content: '"\\a"',
                        whiteSpace: 'pre',
                    },
                },
            },
        },
        [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
            border: 'none',
        },
    }),
};

type MarkdownTableProps = { items: ItemTable[] };

const MarkdownTable: FC<MarkdownTableProps> = ({ items }) => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(theme.breakpoints.down(TABLE_BREAKPOINT));
    const isHalf = items.length > 1;
    const markdownIds = items.map(item => item.id);

    // Handle markdown tables.
    useEffect(() => {
        if (!isMobile) return;

        markdownIds.forEach(markdownId => {
            const markdownBlock = document.getElementById(String(markdownId));
            const tables = markdownBlock?.querySelectorAll('table');

            tables?.forEach((table) => transformTable(table));
        });
    }, [isMobile, markdownIds]);

    return <Box sx={ styles.block }>
        { items.map(item => (
            <Box
                id={ String(item.id) }
                key={ item.id }
                sx={ styles.tableContainer(theme, isHalf) }
            >
                { item.title && <h5>{ item.title }</h5> }
                <ReactMarkdown remarkPlugins={ [gfm] }>
                    { item.table }
                </ReactMarkdown>
            </Box>
        ))}
    </Box>;
};

export default MarkdownTable;
