import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TestimonialIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 30 30' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M15 18.9L17.1 14.1L21.9 12L17.1 9.9L15 5.1L12.9 9.9L8.1 12L12.9 14.1L15 18.9ZM0 30V2.25C0 1.675 0.225 1.15625 0.675 0.69375C1.125 0.23125 1.65 0 2.25 0H27.75C28.325 0 28.8438 0.23125 29.3063 0.69375C29.7688 1.15625 30 1.675 30 2.25V21.75C30 22.325 29.7688 22.8438 29.3063 23.3063C28.8438 23.7688 28.325 24 27.75 24H6L0 30ZM2.25 24.5625L5.0625 21.75H27.75V2.25H2.25V24.5625Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default TestimonialIcon;
