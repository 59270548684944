import header from './Header/Header';
import headerHome from './Header/HeaderHome';
import blogPost from './Content/BlogPost';
import infoWithTestimonials from './Content/InfoWithTestimonials';
import infoWhy from './Content/InfoWhy';
import infoBlocks from './Content/InfoBlocks';
import infoLoyalty from './Content/InfoLoyalty';
import infoTextWithTables from './Content/InfoTextWithTables';
import infoTitleText from './Content/InfoTitleText';
import infoAbout from './Content/InfoAbout';
import faq from './Content/FAQ';
import sidebar1 from './Sidebar/Sidebar1';
import sidebarTitle from './Sidebar/SidebarTitle';
import recentPostCard from './Sidebar/RecentPostCard';
import footer1 from './Footer/Footer1';
import contactUs from './Content/ContactUsCms';

/* sections */
export {
    /* header */
    header,
    headerHome,

    /* content */
    blogPost,
    infoWithTestimonials,
    infoWhy,
    infoBlocks,
    infoLoyalty,
    infoTextWithTables,
    infoTitleText,
    infoAbout,
    faq,
    contactUs,

    /* sidebar */
    sidebar1,
    sidebarTitle,
    recentPostCard,

    /* footer */
    footer1,
};
