import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CancelCircleIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 22 22' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M6.93758 16.2003L11.0001 12.1378L15.0626 16.2003L16.2001 15.0628L12.1376 11.0003L16.2001 6.93783L15.0626 5.80032L11.0001 9.86282L6.93758 5.80032L5.80008 6.93783L9.86258 11.0003L5.80008 15.0628L6.93758 16.2003ZM11.0001 21.8337C9.51953 21.8337 8.12022 21.5493 6.80216 20.9805C5.48411 20.4118 4.33307 19.6354 3.34904 18.6514C2.36501 17.6673 1.58862 16.5163 1.01987 15.1982C0.451123 13.8802 0.166748 12.4809 0.166748 11.0003C0.166748 9.50171 0.451123 8.09338 1.01987 6.77533C1.58862 5.45727 2.36501 4.31074 3.34904 3.33574C4.33307 2.36074 5.48411 1.58887 6.80216 1.02012C8.12022 0.451367 9.51953 0.166992 11.0001 0.166992C12.4987 0.166992 13.907 0.451367 15.2251 1.02012C16.5431 1.58887 17.6897 2.36074 18.6647 3.33574C19.6397 4.31074 20.4115 5.45727 20.9803 6.77533C21.549 8.09338 21.8334 9.50171 21.8334 11.0003C21.8334 12.4809 21.549 13.8802 20.9803 15.1982C20.4115 16.5163 19.6397 17.6673 18.6647 18.6514C17.6897 19.6354 16.5431 20.4118 15.2251 20.9805C13.907 21.5493 12.4987 21.8337 11.0001 21.8337ZM11.0001 20.2087C13.564 20.2087 15.7397 19.3104 17.5272 17.5139C19.3147 15.7173 20.2084 13.5462 20.2084 11.0003C20.2084 8.43644 19.3147 6.26074 17.5272 4.47324C15.7397 2.68574 13.564 1.79199 11.0001 1.79199C8.45425 1.79199 6.28307 2.68574 4.48654 4.47324C2.69001 6.26074 1.79175 8.43644 1.79175 11.0003C1.79175 13.5462 2.69001 15.7173 4.48654 17.5139C6.28307 19.3104 8.45425 20.2087 11.0001 20.2087Z'
                }
                fill={ props.fill || theme.palette.common.white }
            />
        </SvgIcon>
    );
}

export default CancelCircleIcon;
