import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LoanIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 29 29' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M21.1436 25.4431L26.176 20.4343L24.6554 18.9099L21.1436 22.3579L19.7316 20.9424L18.211 22.5031L21.1436 25.4431ZM4.34457 7.98498H21.7228V5.80726H4.34457V7.98498ZM22.1935 29C20.3109 29 18.7058 28.3285 17.3783 26.9856C16.0508 25.6427 15.387 24.0517 15.387 22.2128C15.387 20.3254 16.0508 18.7103 17.3783 17.3673C18.7058 16.0244 20.3109 15.3529 22.1935 15.3529C24.052 15.3529 25.6511 16.0244 26.9906 17.3673C28.3302 18.7103 29 20.3254 29 22.2128C29 24.0517 28.3302 25.6427 26.9906 26.9856C25.6511 28.3285 24.052 29 22.1935 29ZM0 27.5482V2.17772C0 1.5728 0.211194 1.05861 0.633583 0.635169C1.05597 0.211723 1.56887 0 2.17228 0H23.8951C24.4985 0 25.0114 0.211723 25.4338 0.635169C25.8562 1.05861 26.0674 1.5728 26.0674 2.17772V14.0463C25.7295 13.8769 25.3795 13.7378 25.0175 13.6289C24.6554 13.52 24.2813 13.4293 23.8951 13.3567V2.17772H2.17228V23.9186H13.3958C13.4682 24.3542 13.5768 24.7716 13.7216 25.1708C13.8664 25.5701 14.0354 25.9633 14.2285 26.3504L13.0337 27.5482L10.8614 25.3705L8.68914 27.5482L6.51685 25.3705L4.34457 27.5482L2.17228 25.3705L0 27.5482ZM4.34457 20.3254H13.4682C13.5647 19.9383 13.6673 19.5632 13.7759 19.2003C13.8845 18.8373 14.0354 18.4864 14.2285 18.1477H4.34457V20.3254ZM4.34457 14.1552H18.2472C18.7782 13.889 19.3333 13.6773 19.9126 13.52C20.4919 13.3627 21.0953 13.2599 21.7228 13.2115V11.9775H4.34457V14.1552Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default LoanIcon;
