import { FunctionComponent } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import DocumentIcon from '../../../assets/SvgIcons/DocumentIcon';
import SuccessIcon from '../../../assets/SvgIcons/SuccessIcon';
import PaymentIcon from '../../../assets/SvgIcons/PaymentIcon';
import RefreshIcon from '../../../assets/SvgIcons/RefreshIcon';
import PrizeIcon from '../../../assets/SvgIcons/PrizeIcon';
import CancelCircleIcon from '../../../assets/SvgIcons/CancelCircleIcon';
import InfoIcon from '../../../assets/SvgIcons/InfoIcon';
import ProfileOutlinedIcon from '../../../assets/SvgIcons/ProfileOutlinedIcon';
import HeadphoneKidIcon from '../../../assets/SvgIcons/HeadphoneKidIcon';
import BorrowIcon from '../../../assets/SvgIcons/BorrowIcon';
import RatesIcon from '../../../assets/SvgIcons/RatesIcon';
import ApplicationIcon from '../../../assets/SvgIcons/ApplicationIcon';
import BusinessIcon from '../../../assets/SvgIcons/BusinessIcon';
import CoinIcon from '../../../assets/SvgIcons/CoinIcon';
import LocationIcon from '../../../assets/SvgIcons/LocationIcon';
import PhoneIcon from '../../../assets/SvgIcons/PhoneIcon';
import AddressIcon from '../../../assets/SvgIcons/AddressIcon';
import MailIcon from '../../../assets/SvgIcons/MailIcon';
import LoanIcon from '../../../assets/SvgIcons/LoanIcon';
import FaceIcon from '../../../assets/SvgIcons/FaceIcon';
import DoneCircleIcon from '../../../assets/SvgIcons/DoneCircleIcon';
import TestimonialIcon from '../../../assets/SvgIcons/TestimonialIcon';
import PlanChangedIcon from '../../../assets/SvgIcons/PlanChangedIcon';
import ExtraMoneyIcon from '../../../assets/SvgIcons/ExtraMoneyIcon';

const CmsIconMap = {
    document: (props: SvgIconProps) => <DocumentIcon { ...props } />,
    success: (props: SvgIconProps) => <SuccessIcon { ...props } />,
    payment: (props: SvgIconProps) => <PaymentIcon { ...props } />,
    refresh: (props: SvgIconProps) => <RefreshIcon { ...props } />,
    prize: (props: SvgIconProps) => <PrizeIcon { ...props } />,
    cancel: (props: SvgIconProps) => <CancelCircleIcon { ...props } />,
    borrow: (props: SvgIconProps) => <BorrowIcon { ...props } />,
    rates: (props: SvgIconProps) => <RatesIcon { ...props } />,
    support: (props: SvgIconProps) => <HeadphoneKidIcon { ...props } />,
    application: (props: SvgIconProps) => <ApplicationIcon { ...props } />,
    business: (props: SvgIconProps) => <BusinessIcon { ...props } />,
    coin: (props: SvgIconProps) => <CoinIcon { ...props } />,
    info: (props: SvgIconProps) => <InfoIcon { ...props } />,
    profile: (props: SvgIconProps) => <ProfileOutlinedIcon { ...props } />,
    location: (props: SvgIconProps) => <LocationIcon { ...props } />,
    phone: (props: SvgIconProps) => <PhoneIcon { ...props } />,
    address: (props: SvgIconProps) => <AddressIcon { ...props } />,
    mail: (props: SvgIconProps) => <MailIcon { ...props } />,
    loan: (props: SvgIconProps) => <LoanIcon { ...props } />,
    face: (props: SvgIconProps) => <FaceIcon { ...props } />,
    done_circle: (props: SvgIconProps) => <DoneCircleIcon { ...props } />,
    testimonial: (props: SvgIconProps) => <TestimonialIcon { ...props } />,
    repay: (props: SvgIconProps) => <PlanChangedIcon { ...props } />,
    extra_money: (props: SvgIconProps) => <ExtraMoneyIcon { ...props } />,
};

export type CmsIconType = keyof typeof CmsIconMap;

type CmsIconProps = SvgIconProps & {
    name: CmsIconType;
};

const CmsIcon: FunctionComponent<CmsIconProps> = props => {
    if (!CmsIconMap[props.name as CmsIconType]) {
        return <></>;
    }

    return (CmsIconMap[props.name as CmsIconType])(props);
};

export default CmsIcon;
