import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { ItemTestimonial } from '../types/Item';
import { EXTRA_SMALL, KnownBreakpoints } from '../../../common/constants';
import Markdown from './Markdown';

const styles = {
    item: (theme: Theme) => ({
        display: 'flex',
        gap: '23px',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            flexDirection: 'column',
        },
    }),
    image: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            alignItems: 'flex-start',
        },
    }),
    textContainer: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        maxWidth: '260px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            gap: '10px',
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
           textAlign: 'center',
        },
    }),
    message: {
        fontWeight: 600,
        maxWidth: '280px',
    },
    name: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '24px',
    },
};

type ItemTestimonialProps = { item: ItemTestimonial };

const ItemTestimonial: React.FunctionComponent<
    ItemTestimonialProps
> = ({ item }) => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const mobileStyles = isMobile ? { width: '80px', height: '80px' } : {};

    return <Box sx={ styles.item }>
        <Box sx={ styles.image }>
            <Image
                alt={ item.name }
                src={ item.image.data?.attributes.url || '' }
                width={ item.image.data?.attributes?.width || 99 }
                height={ item.image.data?.attributes?.height || 99 }
                style={ mobileStyles }
            />
        </Box>
        <Box sx={ styles.textContainer as any }>
            <Markdown text={ item.message } sx={ styles.message } />
            <Typography component="span" sx={ styles.name }>
                { `${ item.name }, ${ item.location }` }
            </Typography>
        </Box>
    </Box>;
};

export default ItemTestimonial;
