import React, { FunctionComponent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { SectionProps } from '../../types/Section';
import { KnownBreakpoints } from '../../../../common/constants';
import SectionDivider from '../../components/SectionDivider';
import SectionApplyButton from '../../components/SectionApplyButton';
import { InfoTitleText } from '../../types/Content';
import Box from '@mui/material/Box';
import SectionTitle from '../../components/SectionTitle';
import Markdown from '../../components/Markdown';
import BlockTitle from '../../components/BlockTitle';

const styles = {
    item: {
        marginBottom: '50px',
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    blockTitle: {
        marginBottom: '10px',
    },
    blockText: {
        lineHeight: '28px',
    },
    buttonContainer: {
        marginBottom: '60px',
    },
};

const InfoTitleText: FunctionComponent<SectionProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { data: { title, items } } = props as { data: InfoTitleText };

    return <React.Fragment>
        { title && <SectionTitle title={ title } /> }

        { items.map((item) => (
            <Box key={ item.id } sx={ styles.item } >
                <BlockTitle title={ item.title } sx={ styles.blockTitle } />
                <Markdown text={ item.text } sx={styles.blockText} />

                { item.hasApplyButton && <Box sx={ styles.buttonContainer }>
                    <SectionDivider location={ 'bottom' } />
                    <SectionApplyButton
                        direction={ isMobile ? 'column' : 'row'}
                    />
                </Box> }
            </Box>
        )) }
    </React.Fragment>;
};

export default InfoTitleText;
