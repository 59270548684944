type ImageSize = 'thumbnail' | 'small' | 'medium' | 'large';

const getImageAttributes = (attributes: any, size?: ImageSize) => {
    if (!size) {
        return attributes;
    }

    const defaultAttributes = {
        ...attributes,
        width: attributes.width || 368,
    };
    const formatAttributes = attributes.formats[size] || {};
    const newAttributes = { ...attributes, ...formatAttributes};

    return newAttributes || defaultAttributes;
};

export default getImageAttributes;
