import React, { FunctionComponent } from 'react';
import Markdown from './Markdown';
import { InfoWithNumberedItems } from '../types/Block';
import SectionTitle from './SectionTitle';
import InfoItemsNumbered from './InfoItemsNumbered';

type InfoBlockProps = {
    block: InfoWithNumberedItems;
};

const InfoBlock: FunctionComponent<InfoBlockProps> = ({ block }) => {
    return <React.Fragment>
        <SectionTitle title={ block.title } />

        { block.text && <Markdown text={ block.text } /> }

        <InfoItemsNumbered
            items={ block.items }
            hasIcons={ block.hasItemIcons }
            icon={ block.itemIcon }
            direction={ block.itemsDirection }
        />
    </React.Fragment>;
};

export default InfoBlock;
