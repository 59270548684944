import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { FunctionComponent } from 'react';
import { KnownBreakpoints, SMALL } from '../../../../common/constants';
import isEven from '../../../../common/helpers/isEven';
import Markdown from '../Markdown';
import { ItemLoyaltyStatus } from '../../types/Item';
import LoyaltyIcon from '../../../Loyalty/LoyaltyIcon';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        padding: '0 40px',
        [theme.breakpoints.down(SMALL)]: {
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 20px',
        },
    }),
    itemSmall: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
    },
    item: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        height: '170px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            height: '180px',
        },
    }),
    itemFirst: (theme: Theme) => ({
        borderTop: 'none',
        borderLeft: `20px solid ${ theme.palette.common.white }`,
        borderBottom: `20px solid ${ theme.palette.common.white }`,
        borderRadius: '0 0 0 40px',
        marginRight: '40px',
        marginTop: 0,
        height: '150px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            height: '120px',
        },
    }),
    itemOdd: (theme: Theme) => ({
        justifyContent: 'flex-end',
        borderTop: `20px solid ${ theme.palette.common.white }`,
        borderRight: `20px solid ${ theme.palette.common.white }`,
        borderBottom: `20px solid ${ theme.palette.common.white }`,
        borderRadius: '0 40px 40px 0',
        marginLeft: '40px',
        marginTop: '-20px',
    }),
    itemEven: (theme: Theme) => ({
        borderTop: `20px solid ${ theme.palette.common.white }`,
        borderLeft: `20px solid ${ theme.palette.common.white }`,
        borderBottom: `20px solid ${ theme.palette.common.white }`,
        borderRadius: '40px 0px 0px 40px',
        marginRight: '40px',
        marginTop: '-20px',
    }),
    itemLast: (theme: Theme) => ({
        borderTop: `20px solid ${ theme.palette.common.white }`,
        borderLeft: `20px solid ${ theme.palette.common.white }`,
        borderBottom: 0,
        borderRadius: '40px 0 0 0',
        marginRight: '40px',
        marginTop: '-20px',
        height: '150px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            height: '120px',
        },
    }),
    rightItem: {
        height: '170px',
        display: 'flex',
        marginTop: '-20px',
        alignItems: 'center',
        padding: '20px 0 0 30px',
    },
    wrapper: (theme: Theme) => ({
        display: 'flex',
        minWidth: '118px',
        '& > div': {
            minWidth: '118px',
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                transform: 'scale(1.128205128)',
                minWidth: '82px',
            },
        },
        '& > div > span': {
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                transform: 'scale(0.93220339)',
            },
        },
    }),
    itemFirstWrapper: (theme: Theme) => ({
        marginTop: '-28px',
        marginLeft: '-78px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginTop: '-28px',
            marginLeft: '-50px',
        },
    }),
    itemOddWrapper: (theme: Theme) => ({
        marginRight: '-70px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginRight: '-54px',
        },
        '& > div > span': {
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                paddingRight: '30px',
            },
        },
    }),
    itemEvenWrapper: (theme: Theme) => ({
        marginLeft: '-78px',
        '& > div': {
            minWidth: '118px',
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                minWidth: '82px',
                transform: 'scale(1.128205128)',
            },
        },
        '& > div > span': {
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                paddingLeft: '10px',
            },
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginLeft: '-50px',
        },
    }),
    itemLastWrapper: (theme: Theme) => ({
        marginLeft: '-80px',
        marginBottom: '-28px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginLeft: '-50px',
            marginBottom: '-28px',
        },
    }),
    markdownContainer: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        maxWidth: '280px',
        padding: '10px 0 0 30px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '10px 0 0 10px',
        },
        [theme.breakpoints.down(SMALL)]: {
            maxWidth: 'unset',
            width: '100%',
            textAlign: 'center',
        },
    }),
    markdown: (theme: Theme) => ({
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '24px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            fontSize: '12px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down(SMALL)]: {
            fontSize: '14px',
            lineHeight: '24px',
        },
        '& > h4': {
            ...theme.typography.h4,
            lineHeight: '28.13px',
            marginBottom: '8px',
            [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '21.09px',
            },
        },
    }),
};

const getClassName = (index: number, length: number) => {
    if (index === 0) { return 'itemFirst'; }
    if (index === (length - 1)) { return 'itemLast'; }

    return isEven(index) ? 'itemEven' : 'itemOdd';
};

type LoyaltyStatusRoadmapProps = { items: ItemLoyaltyStatus[] };

const LoyaltyStatusRoadmap: FunctionComponent<LoyaltyStatusRoadmapProps> = ({
    items,
}) => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const isSmall = useMediaQuery(
        theme.breakpoints.down(SMALL),
    );
    const itemLength = items.length;

    return isSmall
    ? <Box sx={ styles.container }>
        { items.map((item, idx) => (
            <Box key={ idx } sx={ styles.itemSmall }>
                <LoyaltyIcon status={ item.status } />
                <Box sx={ styles.markdownContainer }>
                    <Markdown
                        text={ item.text }
                        sx={ styles.markdown(theme) }
                    />
                </Box>
            </Box>
        )) }
    </Box>
    : <Box sx={ styles.container }>
        <Box sx={ styles.leftColumn }>
            { items.map((item, idx) => {
                const itemClass = getClassName(idx, itemLength);
                const wrapperClass = `${ itemClass }Wrapper`;

                return <Box key={ item.id } sx={{
                    ...styles.item(theme),
                    ...styles[itemClass](theme),
                }}>
                    <Box sx={{
                        ...styles.wrapper(theme),
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ...styles[wrapperClass](theme),
                    }}>
                        { isMobile && !isEven(idx) &&
                            <Box sx={ styles.markdownContainer }>
                                <Markdown
                                    text={ item.text }
                                    sx={ styles.markdown(theme) }
                                />
                            </Box>
                        }
                        <LoyaltyIcon status={ item.status } />
                        { isEven(idx) && <Box sx={ styles.markdownContainer }>
                            <Markdown
                                text={ item.text }
                                sx={ styles.markdown(theme) }
                            />
                        </Box> }
                    </Box>
                </Box>;
            }) }
        </Box>
        { !isMobile && <Box sx={ styles.rightColumn }>
            { items.map((item, idx) => (
                <Box key={ item.id } sx={ styles.rightItem }>
                    <Box sx={ styles.markdownContainer }>
                        <Markdown
                            text={ !isEven(idx) ? item.text : '' }
                            sx={ styles.markdown(theme) }
                        />
                    </Box>
                </Box>
            )) }
        </Box> }

    </Box>;
};

export default LoyaltyStatusRoadmap;
