import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MailIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 18 14' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M1.91699 13.6663C1.58366 13.6663 1.29199 13.5413 1.04199 13.2913C0.791992 13.0413 0.666992 12.7497 0.666992 12.4163V1.58301C0.666992 1.24967 0.791992 0.958008 1.04199 0.708008C1.29199 0.458008 1.58366 0.333008 1.91699 0.333008H16.0837C16.417 0.333008 16.7087 0.458008 16.9587 0.708008C17.2087 0.958008 17.3337 1.24967 17.3337 1.58301V12.4163C17.3337 12.7497 17.2087 13.0413 16.9587 13.2913C16.7087 13.5413 16.417 13.6663 16.0837 13.6663H1.91699ZM9.00033 7.37467L16.0837 2.72884V1.58301L9.00033 6.12467L1.91699 1.58301V2.72884L9.00033 7.37467Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default MailIcon;
