import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AddressIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 19 19' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M0.875 18.125V4.82812H4.82812V0.875H14.1719V8.78125H18.125V18.125H10.7698V14.1719H8.23021V18.125H0.875ZM2.3125 16.6875H4.82812V14.1719H2.3125V16.6875ZM2.3125 12.7344H4.82812V10.2188H2.3125V12.7344ZM2.3125 8.78125H4.82812V6.26562H2.3125V8.78125ZM6.26562 12.7344H8.78125V10.2188H6.26562V12.7344ZM6.26562 8.78125H8.78125V6.26562H6.26562V8.78125ZM6.26562 4.82812H8.78125V2.3125H6.26562V4.82812ZM10.2188 12.7344H12.7344V10.2188H10.2188V12.7344ZM10.2188 8.78125H12.7344V6.26562H10.2188V8.78125ZM10.2188 4.82812H12.7344V2.3125H10.2188V4.82812ZM14.1719 16.6875H16.6875V14.1719H14.1719V16.6875ZM14.1719 12.7344H16.6875V10.2188H14.1719V12.7344Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default AddressIcon;
